<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <mini-statistics-card
            title="Clients"
            :value="data.totalClients"
            :percentage="{
             
              color: 'text-success',
            }"
            :icon="{
              component: 'ni ni-circle-08',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <mini-statistics-card
            title="Total Emails Sent"
            :value="data.totalEmails"
            :percentage="{
             
              color: 'text-success',
            }"
            :icon="{
              component: 'ni ni-email-83',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <mini-statistics-card
            title="Total SMS Sent"
            :value ="data.totalSms"
            :percentage="{
              value: '',
              color: 'text-success',
            }"
            :icon="{
              component: ' ni ni-chat-round',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <mini-statistics-card
            title="Messages Sent Today"
            :value="data.todayMessages"
            :percentage="{
              value: '',
              color: 'text-danger',
            }"
            :icon="{
              component: 'ni ni-collection',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-lg-12">
          <!-- line chart -->
          <div class="card z-index-2">
            <admin-chart
              id="chart-line"
              title="Performance Line Chart"
              description="Number of SMS and Emails Sent per month"
              :chart="{
                labels: data.labels,
                datasets: [
                  {
                    label: 'Emails',
                    data: data.emailsCount,
                  },
                  {
                    label: 'SMS',
                    data: data.smsCounts,
                  },
                ],
              }"
            />
          </div>
        </div>
      </div>
      <div class="row mt-5" >
          <div class="col-12">
            <div class="card my">
              <div class="card-body px-0 ">
                <h6 class="text-dark text-capitalize py-2 ps-3">Activity Logs</h6>
                <div class="table-responsive p-0">
                  <table class="table align-items-center mb-0">
                    <thead>
                      <tr >
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Email</th>
                        <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7 ps-2">Activity</th>
                        <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7 ps-2">Device</th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Activity Time</th>
                      </tr>
                    </thead>
                    <tbody id="">
                      <tr v-for="(item, index) in data.logs" :key="index" >
                            <td>
                              <div class="d-flex px-2 py-1 align-items-center">
                                <div>
                                  <div class="avatar avatar-sm me-3 bg-gradient-success">
                                    {{
                                      getInitials(
                                          item.name
                                      ).toUpperCase()
                                    }}
                                  </div>
                                </div>
                                <div>
                                  <p class="text-xs font-weight-bold mb-0">{{ item.name }}</p>
                                </div>
                                </div>
                            </td>
                            <td>
                              <p class="text-xs font-weight-bold mb-0">{{ item.email }}</p>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <p class="text-xs font-weight-bold mb-0">{{ item.activity }}</p>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <p class="text-xs font-weight-bold mb-0">{{ item.device }}</p>
                            </td>
                         
                            <td class="align-middle text-center">
                              <span class="text-secondary text-xs font-weight-bold">{{  dateTime(item.created_at) }}</span>
                            </td>
                          </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="data.logs.length > 8" class="text-end me-4">
                  <router-link to="/logs" class=""> <button class="btn mb-0 btn-success btn-xs null null ms-auto ">
                    View More <i class="fas fa-arrow-right  me-1" aria-hidden="true"></i>
                  </button> </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </template>
  <script>
  import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
  import AdminChart from "@/examples/Charts/AdminChart.vue";
  import moment from "moment";
  import { ref, onMounted, onBeforeMount, reactive, watch  } from "vue";
  import {fetchDashboard} from "@/api/admin/admin-dashboard"
  import { useStore } from 'vuex';
  const dashboardInfo = ref({})
  
  const data = reactive({
      totalEmails: '',
      totalSms: '',
      totalClients: '',
      logs:[],
      labels:[],
      smsCounts:[],
      emailsCount:[],
      todayMessages:"",
    });
  
  export default {
    name: "dashboard",
    data() {
     
      return {
        iconBackground: "bg-gradient-success",
      
      };
    },
  
    components: {
      MiniStatisticsCard,
      AdminChart,
    },
      
    setup(){
     
     const store = useStore();
      const showModal = ref(false)
      
  
      const fetchData = async () => {
        store.commit('isLoading', true)
        try{  
        const response = await fetchDashboard();
        dashboardInfo.value = response.data;
  
        
        console.log('response isssssss',data.totalEmails)
       
        
      } catch (error) {
        console.log(error);
      } finally {
        // isLoading.value = false;
        store.commit('isLoading', false)
      }
    };
  
    onMounted(()=>{
      watch(
      () =>  dashboardInfo.value,
      (newValue) => {
        // Update profileForm when userInfo changes
        data.totalEmails = newValue.totalEmails;
        data.totalSms = newValue.totalSms;
        data.totalClients = newValue.totalClients;
        data.todayMessages = newValue.todayMessages;
        data.labels = newValue.labels;
        data.smsCounts =  newValue.smsCounts;
        data.emailsCount =  newValue.emailsCount;
        data.logs = newValue.logs;
      
        // ... other properties
      },
      { immediate: true } // Trigger the watcher immediately
    );
   
    })
   
  
    
  
    onBeforeMount(() => {
      fetchData();
     // Fetch data when the component is about to mount
    })
  
    const getInitials = (name) => {
        if (name) {
            const nameSplit = name?.split(" ");
            const firstInitials = nameSplit[0]?.charAt(0);
            const secondInitials = nameSplit[1]?.charAt(0);
  
            return (
                (firstInitials ? firstInitials : "") +
                (secondInitials ? secondInitials : "")
            );
            }
            return "";
            };
  
    const dateTime = (value) => {
        return moment(value).format("Do MMM YYYY HH:mm:ss");
    };
      return {
        showModal,
        fetchData,
        data,
        getInitials,
        dateTime,
      }
    },
    // mounted(){
    //   this.ft
    // }
   
  };
  </script>
     
  <style scoped>
  .topup{
    margin-right:-9px;
  }
  </style>