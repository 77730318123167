<template>
    <section class="py-4 container-fluid">
            
            <div class="text-start col-md-6 mx-auto">
              <h5>Send A Single Email</h5>
              <p> To send a single email, enter the email, subject, name, message, and select or type the sender email. If name is not provided, it will default to customer</p>
            </div>
        <div class="card z-index-0 col-md-6 mx-auto">
            <div class="card-body">
                <el-form
                ref="emailFormRef"
                :model="emailForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm "
                size="large"
                status-icon
                :scroll-to-error="true"
                label-position="top"
                :hide-required-asterisk="true"
                >
                
                <el-form-item label="Name" prop="name">
                    <el-input v-model="emailForm.name" placeholder="Name" />
                </el-form-item>
                <el-form-item label="Email" prop="email">
                    <el-input v-model="emailForm.email" placeholder="Email" />
                </el-form-item>
                <el-form-item label="Subject" prop="subject">
                    <el-input v-model="emailForm.subject" placeholder="Subject" />
                </el-form-item>
                <div class="mt-4 editor-container mb-2">
                    <label class="el-form-item__label">Message</label>
                    <div ref="compose_sms_editor"></div>
              
                <label class="el-form-item__label mt-2">Sender Email</label>
                <div class="d-flex align-items-center">
                  <el-select v-model="emailForm.sender_email"
                    prop="email"
                    label="" class="w-100" 
                    placeholder="" size=""
                    filterable
                    clearable>
                    <el-option
                      v-for="item in senders.data"
                      :key="item.id"
                      :label="item.sender_email"
                      :value="item.sender_email"
                    />
                </el-select>
                <div class="">
                    <i
                      class="fa fa-plus password-icon"
                      @click="goToSenders"
                    ></i>
                  </div>
                </div>
             
                    <div class="text-start">
                        <el-button
                          :style="{ background: $themeColor() }"
                          class="my-5 mb-2 text-white"
                          :loading="isLoading"
                          @click.prevent="submitForm(emailFormRef)"
                          >Send Email</el-button >
                    </div> 
                  </div>
                </el-form>
            </div>
        </div>

    </section>
</template>
<script>
import { ref, reactive, onMounted, watch } from "vue";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import { ElNotification } from "element-plus";
import {singleEmail} from "@/api/email"
import {fetchUnpagSenderEmail} from "@/api/email"
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const isLoading = ref(false);
export default {
  name: "communication",
  components: {
  //
   
  },
 
    setup(){
      const router = useRouter();
      const goToSenders = ()=>{
        router.push('/sender-email-settings')
      }
      const store = useStore();
    const sender_email_address = localStorage.getItem('sender_email')
    const senders = ref([]);
    const characterCount = ref(0);
    const compose_sms_editor = ref(null);
    const emailFormRef = ref(null);
    const emailForm =reactive({
      name :"",
      email: "",
      subject: "",
      message:"",
      sender_email:"",
    })

    const rules = reactive({
      message: [
        {
          required: true,
          message: "Please input Message",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
    });
    const fetchData = async()=>{
            store.commit('isLoading', true)
            try{
                await 
                fetchUnpagSenderEmail()
                    .then((response) => {
                      senders.value = response.data;
                });
            }catch{err => {console.log(err)}}
            finally{
                store.commit('isLoading', false)
            }
        }
    
   
      onMounted(() => {
        fetchData()
        
        const quill = new Quill(compose_sms_editor.value, {
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, 4, false] }],
              ["bold", "italic", "underline", "strike", "link"],
              [{ list: "bullet" }, { list: "ordered" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ align: [] }],
              ["image"],
              ["clean"],
              ["page-break"],
              ["template"],
              [{ 'font': [ 'sans-serif', 'serif'] }],
              [{ 'color': [] }],
              ["copy", "cut", "paste"],
              ["preview", "fullscreen"],
              ["table"],
  
            ],
          },
          theme: "snow",
          fonts: {
            'customFont': 'Your Custom Font, sans-serif',
            'Helvetica': 'Helvetica, sans-serif',
            'Arial': 'Arial, sans-serif',
            'Times New Roman': '"Times New Roman", serif',
            'Comic Sans': '"Comic Sans MS", cursive',
            'Tahoma': 'Tahoma, sans-serif',
            'script': 'Script Font, cursive',
            'monospaced': 'Monospace Font, monospace',
            'display': 'Display Font, cursive',
          },
          formats: [
            "bold",
            "underline",
            "strike",
            "header",
            "italic",
            "link",
            "list",
            "indent",
            "align",
            "image",
            "page-break",
            "template",
            "font",
            "link",
            "image",
            "color",
            "preview",
            "fullscreen",
            "table",
          ],
          placeholder: "Compose Message",
        });
  
        quill.root.classList.add('ql-font-customFont');
  
        quill.getModule('toolbar').addHandler('color', (value) => {
          if (value === 'reset') {
            quill.format('color', false);
          } else {
            quill.format('color', value);
          }
        });
        quill.on("text-change", () => {
        emailForm.message = quill.root.innerHTML;
      });
  
      });
    
      watch(emailForm, () => {
        characterCount.value = emailForm.message.length;
    });

    const submitForm = async (formEl) => {
      const message = emailForm.message;
      if (message === '') {
        ElNotification({
          title: "Error",
          message: "Please type a message",
          type: "error",
        })
        return;
      } 
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true
          const formData = new FormData();
          formData.append("name", emailForm.name);
          formData.append("email", emailForm.email);
          formData.append("subject", emailForm.subject);
          formData.append("message", emailForm.message);
          formData.append("sender_email", emailForm.sender_email);

          singleEmail(formData)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  ElNotification({
                    title: "Success",
                    message: response.data.message,
                    type: "success",
                  });
                  emailForm.name = ""
                  emailForm.email = ""
                  emailForm.subject = ""
                  emailForm.message = ""
                  const quill = compose_sms_editor.value.__quill;
                  quill.setText('');
                  // Reset the editor to its initial state
                  quill.clipboard.dangerouslyPasteHTML('<div data-placeholder="Compose Message"></div>');
                }else if (response.data.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.data.error,
                    type: 'error',
                  });
              }  else {
                  Object.values(response.data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),
              )
              .finally(() => {
               
                isLoading.value = false
          });
              } else {
                console.log("error submit!", fields);
              }
            });
          };

        return{
            emailForm,
            emailFormRef,
            rules,
            compose_sms_editor,
            characterCount,
            submitForm,
            isLoading,
            sender_email_address,
            senders,
            goToSenders
            
        }
    }
};
</script>
<style scoped>
hr{
    color:green;
}
.custom-select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 20px;
}
.tbtn{
    margin-top: -2%;
}
.text-red {
  color: red;
}
small{
    font-size: 12px;
}
.editor-container{
    height:10rem !important;
    max-height:60rem !important;
}
.card{
    height: 53rem;
}

@media (min-width: 768px) {
  .card{
    height: 50rem;
}
.password-icon {
  cursor: pointer;
  margin-left: 5px;
}
}
</style>