<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-user-o text-primary me-2" aria-hidden="true"></i>Add Group Member</div>
      
        <el-form
            ref="groupMemberFormRef"
            :model="groupMemberForm"
            :rules="rules"
            label-width="150px"
            size=""
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <label class="el-form-item__label">Group</label>
                    <el-select v-model="groupMemberForm.group" label="" class="w-100" placeholder="" size=""  filterable>
                        <el-option
                        v-for="item in groups"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-md-12 mb-2">
                    <el-form-item label="Name" prop="name">
                        <el-input v-model="groupMemberForm.name" />
                    </el-form-item>
                </div>
                <div class="col-md-12 mb-2">
                    <el-form-item label="Email" prop="email">
                        <el-input v-model="groupMemberForm.email" />
                    </el-form-item>
                </div>
            </div>
          </div>
          <div class="card--footer">
            <el-button
                type="danger"
                :loading="isLoading"
                @click="$emit('close-modal')"
            >Cancel</el-button
            >
            <el-button
                type="success"
                :loading="isLoading"
                @click="submitForm(groupMemberFormRef)"
            >Create</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref, onMounted } from "vue";
  import {fetchUnpagGroup} from "@/api/groups"
  import { useStore } from 'vuex';
  import { ElNotification } from "element-plus";
  import {createGroupMember} from "@/api/groups"
  const groupMemberFormRef = ref();


  export default {
    name: "email-form",
  
    props: {
      refresh: {
        type: Object,
        required: false,
      },
    },
  
    setup(props, {emit}) {
        const isLoading = ref(false)
        const store = useStore();
        const groups = ref([])
        const groupMemberForm = reactive({
            name:'',
            email:'',
            description:'',
            group:''
            
        });
        const rules = reactive({
            email: [
                {
                required: true,
                message: "Please input email address",
                trigger: "blur",
                },
                {
                type: "email",
                message: "Please input correct email address",
                trigger: ["blur", "change"],
                },
            ],
            name: [
                {
                required: true,
                message: "Please input name",
                trigger: ["blur", "change"],
                },
            ],
            group: [
                {
                required: true,
                message: "Please input your password",
                trigger: ["blur", "change"],
                },
            ],
        });
      
        const fetchData = async()=>{
            store.commit('isLoading', true)
            try{
                await 
                    fetchUnpagGroup()
                    .then((response) => {
                    groups.value = response.data;
                    console.log('groups', groups.value)
                });
            }catch{err => {console.log(err)}}
            finally{
                store.commit('isLoading', false)
            }
        } 
        onMounted(()=>{
            fetchData()
        })
      
        const submitForm = async (formEl) => {
            if (!formEl) return;
            await formEl.validate((valid, fields) => {
                if (valid) {
                isLoading.value = true
                const formData = new FormData();
                formData.append("name", groupMemberForm.name);
                formData.append("email", groupMemberForm.email);
                formData.append("group", groupMemberForm.group);

                createGroupMember(formData)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                        ElNotification({
                            title: "Success",
                            message: response.data.message,
                            type: "success",
                        });
                        props.refresh()
                        emit('close-modal')
                        }else if (response.data.error) {
                        ElNotification({
                            title: 'Error',
                            message: response.data.error,
                            type: 'error',
                        });
                    }  else {
                        Object.values(response.data.errors).forEach((val) =>
                            ElNotification({
                                title: "Error",
                                message: val[0],
                                type: "error",
                            })
                        );
                        }
                    })
                    .catch((err) =>
                        console.log(err),
                    )
                    .finally(() => {
                        isLoading.value = false
                });
              } else {
                console.log("error submit!", fields);
              }
            });
        };
        
  
      return {
        groupMemberForm ,
        groupMemberFormRef,
        props,
        rules,
        groups,
        submitForm,
        isLoading,
        
      };
    },

  };
  </script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 500px;
  height: 450px; 
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}
.card-body {
  height: auto;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}


.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
.password-icon {
  cursor: pointer;
  margin-left: 5px;
}
</style>
  











  