<template>
    <div class="container-fluid py-4">
        <div class="text-end mb-3 px-2">
            <FilterTablesForm
                :show_admin_group_emails_table_filter="true"
                :filter_init="filter_params"
                :filterCallBack="_filterCallBack"
            />
           </div>
      
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-success shadow-primary border-radius-lg pt-4 pb-3">
                <h6 class="text-white text-capitalize ps-3">Group Failed Messages</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0 " v-if="emails.data && emails.data.length > 0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr >
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Business Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Customer Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Email</th>
                      <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7 ps-2">Sent By</th>
                      <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7 ps-2">Subject</th>
                      <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7 ps-2">Message</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Group</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sent At</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                    </tr>
                  </thead>
                  <tbody id="">
                    <tr v-for="(item, index) in emails.data" :key="index" >
                          <td>
                            <div class="d-flex px-2 py-1 align-items-center">
                              <div>
                                <div class="avatar avatar-sm me-3 bg-gradient-success">
                                  {{
                                    getInitials(
                                        item.client?.businessName
                                    ).toUpperCase()
                                  }}
                                </div>
                              </div>
                              <div>
                                <p class="text-xs font-weight-bold mb-0">{{ item.client?.businessName}}</p>
                              </div>
                              </div>
                          </td>
                          <td >
                            <p class="text-xs font-weight-bold mb-0">{{ item.customerName }}</p>
                          </td>
                          <td >
                            <p class="text-xs font-weight-bold mb-0">{{ item.email }}</p>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <p class="text-xs font-weight-bold mb-0">{{ item.sentBy }}</p>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <p class="text-xs font-weight-bold mb-0  truncate-text">{{ item.subject }}</p>
                          </td>
                          
                            <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold truncate-text">{{ removeTags(item.message) }}</span>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <p class="text-xs font-weight-bold mb-0 ">{{ item.status }}</p>
                          </td>
                          <td class="align-middle text-center">
                            <p class="text-xs font-weight-bold mb-0">{{ item.groupName }}</p>
                          </td>
                       
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">{{  dateTime(item.created_at) }}</span>
                          </td>
                          <td class="align-middle text-center">
                            <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                              <i class="fa fa-ellipsis-v text-secondary"></i>
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                            <el-dropdown-item
                                class="text-secondary font-weight-bold text-xs"
                                @click="viewemail({message:item.message, subject:item.subject})"
                            ><i class="fa fa-eye" aria-hidden="true"></i>
                              View</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                          </td>
                        
                        </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <el-empty :image-size="200" description="No Data" />
            </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
    <div class="d-flex justify-content-center mb-3 px-2" v-if="emails.data && emails.data.length > 0">
        <pagination
            :totalPages="total_pages"
            :perPage="per_page"
            :currentPage="current_page"
            @pagechanged="onPageChange"
        />
    </div>
    <SentemailsForm
      v-if="showModal"
      @close-modal="showModal = false"
      :email="emailMessage"
    />
    </template>
    <script>
     import SentemailsForm from "@/components/Modals/SentemailsForm.vue"
     import Pagination from '@/components/comms/Pagination.vue';
     import { ref, h } from "vue";
     import moment from "moment";
     import FilterTablesForm from "../FilterTablesForm.vue";
     import {ElMessage, ElMessageBox} from "element-plus";
     import { removeMessage } from "@/api/email";
     const filter_params = ref({});
     const date = ref('')
     const showModal = ref(false)
     const emailMessage = ref('')
     
    export default {
      name: 'EmailSentBox',
      props: {
        emails: {
        type: Object,
        required: true,
        },
        total_pages: {
        required: true,
        },
        per_page: {
        required: true,
        },
        current_page: {
        required: true,
        },
        refresh: {
        type: Function,
        required: true,
        }
     },
      components: {
        Pagination,
        FilterTablesForm,
        SentemailsForm
      },
      methods: {
    removeTags(htmlString) {
      return htmlString.replace(/<[^>]+>/g, '');
      },
    },
     
    
      setup(props){
       
        const onPageChange = (page) => {
          // paginate.current_page = page
          props.refresh(filter_params, page)
        };

        const getInitials = (name) => {
            if (name) {
                const nameSplit = name?.split(" ");
                const firstInitials = nameSplit[0]?.charAt(0);
                const secondInitials = nameSplit[1]?.charAt(0);

                return (
                    (firstInitials ? firstInitials : "") +
                    (secondInitials ? secondInitials : "")
                );
                }
                return "";
                };

        const dateTime = (value) => {
            return moment(value).format("Do MMM YYYY HH:mm:ss");
        };
        const _filterCallBack = (filter_data) => {
            filter_params.value = filter_data;
            props.refresh(filter_params, 0);
            };

        const deleteMessage = async (id) => {
            ElMessageBox.confirm(
                h(
                    "div",
                    { style: "color: black, font-size: 14px" },
                    "The message will be deleted. Continue?"
                ),

                "Delete Message",
                {
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                  type: "warning",
                  center: true,
                }
            ).then(async () => {
              try {
                // isLoading.value = true;
                const { message, success } = await removeMessage(id);

                if (success) {
                  props.refresh();
                  ElMessage({
                    type: "success",
                    message: message,
                  });
                } else {
                  ElMessage({
                    type: "error",
                    message: message,
                  });
                }
              } catch (error) {
                ElMessage({
                  type: "error",
                  message: error.message,
                });
              } finally {
                // isLoading.value = false;
              }
            });
          };
          const viewemail = (message) => {
            emailMessage.value = message;
            showModal.value = true;
          };
            
            return {
                _filterCallBack,
                filter_params,
                onPageChange,
                getInitials,
                dateTime,
                date,
                deleteMessage,
                viewemail,
                emailMessage,
                showModal


            }
    },
     
    }
    </script>
    <style scoped>
    .pag{
      align-content: center;
    }
    @media (min-width: 768px) {
      .pag{
        margin-left: 35%;
      }
    }
    .truncate-text {
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    max-width: 150px; 
    display: inline-block; 
  }
    </style>