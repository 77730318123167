import axios from 'axios'

export const fetchSmsReport = async(url) => {
    let response = await axios
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const smsReportExcel = async(url) => {
    let response = await axios
        .get(url, {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const smsReportPdf = async(url) => {
    let response = await axios
        .get(url, {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchEmailReport = async(url) => {
    let response = await axios
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const emailReportExcel = async(url) => {
    let response = await axios
        .get(url, {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const emailReportPdf = async(url) => {
    let response = await axios
        .get(url, {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchGroupSmsReport = async(url) => {
    let response = await axios
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const groupSmsReportExcel = async(url) => {
    let response = await axios
        .get(url, {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchGroupEmailReport = async(url) => {
    let response = await axios
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const groupEmailReportExcel = async(url) => {
    let response = await axios
        .get(url, {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const groupEmailReportPdf = async(url) => {
    let response = await axios
        .get(url, {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const groupSmsReportPdf = async(url) => {
    let response = await axios
        .get(url, {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};


