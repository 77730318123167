import axios from "axios";
const sms_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=log:like;device:like;slug:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.customer_name)
        url = url + "&customer_name=" + filter.value.customer_name;

    if (filter.value != undefined && filter.value.phone)
    url = url + "&phone=" + filter.value.phone;

    if (filter.value != undefined && filter.value.user)
    url = url + "&sent_by=" + filter.value.user;

    if (filter.value != undefined && filter.value.type)
    url = url + "&type=" + filter.value.type;


    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;


    // url = url.replace("fetch-success-sms?&", "fetch-success-sms?");

    return url;
};
export const fetchSuccessfulSms = async(filter, page) => {
    let response = await axios
        .get(sms_url("/fetch-success-sms?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const singleSms = async(postBody) => {
    let response = await axios
        .post("/single-sms", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const removeMessage = async(id) => {
    let response = await axios
        .delete(`delete-sms/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const bulkSms = async(postBody) => {
    let response = await axios
        .post("/bulk-sms", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const sampleDownload = async() => {
    let response = await axios
        .get('sample-sms', {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const upload = async(body) => {
    let response = await axios
        .post("sms-upload", body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchFailedSms = async(filter, page) => {
    let response = await axios
        .get(sms_url("/failed-sms?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};




