import axios from "axios";

const email_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=log:like;device:like;slug:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.customer_name)
        url = url + "&customer_name=" + filter.value.customer_name;

    if (filter.value != undefined && filter.value.user)
    url = url + "&sent_by=" + filter.value.user;

    if (filter.value != undefined && filter.value.group)
    url = url + "&group=" + filter.value.group;

    if (filter.value != undefined && filter.value.email)
    url = url + "&email=" + filter.value.email;

    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;

    return url;
};


export const createGroup = async(postBody) => {
    let response = await axios
        .post("/create-group", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const fetchGroup = async(filter, page) => {
    let response = await axios
        .get("/fetch-groups?", filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchGroupMembers = async(filter, page) => {
    let response = await axios
        .get("/fetch-group-members?", filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchUnpagGroup = async() => {
    let response = await axios
        .get('fetch-unpag-groups')
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};


export const createGroupMember = async(postBody) => {
    let response = await axios
        .post("/create-group-member", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const updateGroup = async(postBody) => {
    let response = await axios
        .post("/update-group", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const fetchMembersGroup = async(filter, page, id) => {
    let response = await axios
        .get("/fetch-members-group?id="+id, filter, page, id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeGroup = async(id) => {
    let response = await axios
        .delete(`delete-group/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateGroupMember = async(postBody) => {
    let response = await axios
        .post("/update-group-member", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const removeGroupMember = async(id) => {
    let response = await axios
        .delete(`delete-group-member/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const sendEmail = async(postBody) => {
    let response = await axios
        .post("/send-group-email", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const fetchSuccessfulEmails = async(filter, page) => {
    let response = await axios
        .get(email_url("/fetch-success-group-emails?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchFailedEmails = async(filter, page) => {
    let response = await axios
        .get(email_url("/fetch-failed-group-emails?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const sampleDownload = async() => {
    let response = await axios
        .get('sample-member-email', {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const importGroupMember = async(body) => {
    let response = await axios
        .post("/import-email-group-member", body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};








