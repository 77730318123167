<template>
  <navbar btn-background="bg-gradient-primary" />
  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
    }"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Welcome!</h1>
          <p class="text-white text-lead">
            Use these awesome forms to login or create new account in your
            project for free.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Sign In</h5>
          </div>
          <div class="card-body">
            <el-form
              ref="loginFormRef"
              :model="loginForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm"
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
              >
              <el-form-item label="Name" prop="name">
                <el-input v-model="loginForm.name" />
              </el-form-item>
              <el-form-item label="Email" prop="email">
                <el-input v-model="loginForm.email" />
              </el-form-item>
              <el-form-item label="Business Name" prop="businessName">
                <el-input v-model="loginForm.businessName" />
              </el-form-item>
              <el-form-item label="Password" prop="password">
                <el-input
                  v-model="loginForm.password"
                  type="password"
                  :show-password="true"
                />
              </el-form-item>
              <el-form-item label="Confirm Password" prop="confirm_password">
                <el-input
                  v-model="loginForm.confirm_password"
                  type="password"
                  :show-password="true"
                />
              </el-form-item>

              <div class="text-center">
                <soft-button
                  color="success"
                  @click.prevent="submitForm(loginFormRef)"
                  full-width
                  variant="gradient"
                  class="my-4 mb-2"
                  >Sign In</soft-button
                >
              </div> 
              <p class="text-sm mt-3 mb-0">
                  Don't have an account?
                  <router-link
                    :to="{ name: 'Sign Up' }"
                    class="text-dark font-weight-bolder"
                  >
                    Sign up
                  </router-link>
                </p>
              </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
import { login } from "../api/authentication";
import {  reactive, ref } from "vue";
import { useRouter } from 'vue-router';
import { mapMutations } from "vuex";
import { ElNotification, ElMessage } from "element-plus";
import { h } from "vue";


export default {
  name: "LogIn",
  components: {
    Navbar,
    AppFooter,
    SoftButton,
  },


  setup (){
    const loginFormRef = ref(null);
    const router = useRouter();
    const loginForm =reactive({
      email: '',
      password: ''
    })

    const rules = reactive({
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
      password: [
        {
          required: true,
          message: "Please input your password",
          trigger: ["blur", "change"],
        },

        {
          min: 3,
          max: 255,
          message: "Minimum characters should be 3",
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl) => {
      console.log('button clicked')
      if (!formEl) return
         await formEl.validate((valid) => {
          if (valid) {
              let body = {
                  grant_type: 'password',
                  client_id: '4',
                  client_secret: 'weii4eVviiIBNEb3S3E12xTi2I8RTlZq39WchbkJ',
                  username: loginForm.email,
                  password: loginForm.password
              }
              login(body)
                  .then((response) => {
                    if(response.status == 200){
                      console.log(response);
                      localStorage.setItem('access_token', response.data.access_token);
                      localStorage.setItem('isAuthenticated', 1);
                      router.push('/dashboard');
                      ElMessage({
                          message: h(
                            "i",
                            { style: "color: teal" },
                            `Welcome ${
                              response.data.user.name 
                            } to your dashboard`
                          ),
                          type: "success",
                        });
                    } else if (response.status == 401) {
                      ElNotification({
                        title: "Error",
                        message:
                          "Invalid login credentials. Please confirm login details and try again.",
                        type: "error",
                      });
                    } else {
                      ElNotification({
                        title: "Error",
                        message: response.data.message,
                        type: "error",
                      });
                    }
                })
            
                .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
                
            }
         });
      }

    return {
      loginForm,
      loginFormRef,
      submitForm,
      rules
    }
  },
  
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    
  },
  computed: {
    emailRules() {
      return [
        {
          required: true,
          message: 'Email is required',
          trigger: 'blur',
        },
        {
          type: 'email',
          message: 'Invalid email format',
          trigger: 'blur',
        },
      ];
    },
  },
};
</script>







  
 
  
 
  