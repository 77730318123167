<template>
  <section class="py-4 container-fluid">
          
          <div class="text-start col-md-6 mx-auto">
            <h5>Send A Group Sms</h5>
            <p> To send a group sms, enter the group, and message. If name is not provided, it will default to customer</p>
          </div>
      <div class="card z-index-0 col-md-6 mx-auto">
          <div class="card-body">
              <el-form
              ref="smsFormRef"
              :model="smsForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm "
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
              >
            
              
              <label class="el-form-item__label">Group</label>
              <el-select v-model="smsForm.group" label="" class="w-100" placeholder="" size=""  filterable clearable>
                  <el-option
                  v-for="item in groups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  />
              </el-select>
              <div class="mt-4 editor-container ">
                  <label class="el-form-item__label">Message</label>
                  <div ref="compose_sms_editor"></div>
                  <small :class="{ 'text-red': characterCount > 160 }">Character Count: {{ characterCount }}/160</small>

                  <div class="text-start">
                      <el-button
                      :style="{ background: $themeColor() }"
                        class="my-4 mb-2 text-white"
                        :loading="isLoading"
                        @click.prevent="submitForm(smsFormRef)"
                    >Send SMS</el-button >
                  </div> 
              </div>
              </el-form>
          </div>
      </div>

  </section>
</template>
<script>
import { ref, reactive, onMounted, watch } from "vue";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import { ElNotification } from "element-plus";
import {sendSms} from "@/api/groups/sms"
import {fetchUnpagGroupSms} from "@/api/groups/sms"
import { useStore } from 'vuex';

const isLoading = ref(false)
export default {
name: "communication",
components: {
 //
},

  setup(){
  const groups = ref([])
  const store = useStore();
  const characterCount = ref(0);
  const compose_sms_editor = ref(null);
  const smsFormRef = ref(null);
  const smsForm =reactive({
    group :"",
    message:""
  })

  const rules = reactive({
    message: [
      {
        required: true,
        message: "Please input Message",
        trigger: "blur",
      },
    ],
    phone: [
      {
        required: true,
        message: "Please input phone",
        trigger: "blur"
      },
    ],
  });
    const fetchData = async()=>{
      store.commit('isLoading', true)
      try{
          await 
              fetchUnpagGroupSms()
              .then((response) => {
              groups.value = response.data;
              console.log('groups', groups.value)
          });
      }catch{err => {console.log(err)}}
      finally{
          store.commit('isLoading', false)
      }
    } 
  
      const callQuill = ()=>{
      const quill = new Quill(compose_sms_editor.value, {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "strike", "link"],
            [{ list: "bullet" }, { list: "ordered" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["image"],
            ["clean"],
            ["page-break"],
            ["template"],
            [{ 'font': [ 'sans-serif', 'serif'] }],
            [{ 'color': [] }],
            ["copy", "cut", "paste"],
            ["preview", "fullscreen"],
            ["table"],

          ],
        },
        theme: "snow",
        fonts: {
          'customFont': 'Your Custom Font, sans-serif',
          'Helvetica': 'Helvetica, sans-serif',
          'Arial': 'Arial, sans-serif',
          'Times New Roman': '"Times New Roman", serif',
          'Comic Sans': '"Comic Sans MS", cursive',
          'Tahoma': 'Tahoma, sans-serif',
          'script': 'Script Font, cursive',
          'monospaced': 'Monospace Font, monospace',
          'display': 'Display Font, cursive',
        },
        formats: [
          "bold",
          "underline",
          "strike",
          "header",
          "italic",
          "link",
          "list",
          "indent",
          "align",
          "image",
          "page-break",
          "template",
          "font",
          "link",
          "image",
          "color",
          "preview",
          "fullscreen",
          "table",
        ],
        placeholder: " You can use {name} as a placeholder for name e.g Dear {name}, thank you for using our services",
      });

      quill.root.classList.add('ql-font-customFont');

      quill.getModule('toolbar').addHandler('color', (value) => {
        if (value === 'reset') {
          quill.format('color', false);
        } else {
          quill.format('color', value);
        }
      });
      quill.on("text-change", () => {
      smsForm.message = quill.root.textContent;
    });
    
  }
   
  onMounted(() => { 
    callQuill()
    fetchData()
    
  });
    watch(smsForm, () => {
      characterCount.value = smsForm.message.length;
  });

  const submitForm = async (formEl) => {
    const message = smsForm.message;
    if (message === '') {
      ElNotification({
        title: "Error",
        message: "Please type a message",
        type: "error",
      })
      return;
    } 
    if (!formEl) return;
    await formEl.validate((valid, fields) => {
      if (valid) {
        isLoading.value = true
        const formData = new FormData();
        formData.append("group", smsForm.group);
        formData.append("message", smsForm.message);

        sendSms(formData)
            .then((response) => {
              console.log('sms response',response.data);
              if (response.data.success) {
                ElNotification({
                  title: "Success",
                  message: response.data.message,
                  type: "success",
                });
                smsForm.group = ""
                smsForm.message = ""
                const quill = compose_sms_editor.value.__quill;
                quill.setText('');
                // Reset the editor to its initial state
                quill.clipboard.dangerouslyPasteHTML('<div data-placeholder="Compose Message"></div>');
                }else if (response.data.error) {
                ElNotification({
                  title: 'Error',
                  message: response.data.error,
                  type: 'error',
                });
            }  else {
                Object.values(response.data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                );
              }
            })
            .catch((err) =>
                console.log(err),
            )
            .finally(() => {
              
              isLoading.value = false
        });
            } else {
              console.log("error submit!", fields);
            }
          });
        };

      return{
          smsForm,
          smsFormRef,
          rules,
          compose_sms_editor,
          characterCount,
          submitForm,
          isLoading,
          groups,
          
          
      }
  }
};
</script>
<style scoped>
hr{
  color:green;
}
.custom-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 20px;
}
.tbtn{
  margin-top: -2%;
}
.text-red {
color: red;
}
small{
  font-size: 12px;
}
.editor-container{
  height:10rem !important;
  max-height:60rem !important;
}
.card{
  height: 37rem;
}
@media (min-width: 768px) {
.card{
  height: 35rem;
}
}

</style>



















