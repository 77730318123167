import axios from "axios";

export const login = async(postBody) => {
    let response = await axios
        .post("oauth/token", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};


export const register = async(postBody) => {
    let response = await axios
        .post("/register", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const logout = async(postBody) => {
    let response = await axios
        .get("/logout", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};