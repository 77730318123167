/**
=========================================================
* Communication System - v1.0.0
=========================================================

Coded by Moses Kamuiru

=========================================================

*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import themeColorPlugin from '@/api/theme-color';



import axios from 'axios';
axios.defaults.baseURL= "https://jobs.swifthub.co.ke/api/v1/"
// axios.defaults.baseURL= "http://127.0.0.1:8000/api/v1/"
axios.interceptors.request.use((config) => {
  config.headers.Accept = "application/json";
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});




const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ElementPlus)
appInstance.use(SoftUIDashboard);
appInstance.use(VueTelInput);
appInstance.use(themeColorPlugin, store);
appInstance.mount("#app");
