import axios from "axios";

const ticket_url = (root, filter, page) => {
    var url = root;
    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.business_name)
    url = url + "&business_name=" + filter.value.business_name;

    if (filter.value != undefined && filter.value.name)
    url = url + "&status=" + filter.value.name;

    return url;
};


export const fetchTickets = async(filter, page) => {
    let response = await axios
        .get(ticket_url("/admin-fetch-tickets?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const closeTicket = async(id) => {
    let response = await axios
        .get(`close-ticket/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}