import axios from "axios";

export const raiseTicket = async(postBody) => {
    let response = await axios
        .post("/raise-ticket", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const fetchTickets = async(filter, page) => {
    let response = await axios
        .get("/fetch-tickets?", filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};


