<template>
    <section class="py-4 container-fluid">
        <el-tabs type="border-card">
            <el-tab-pane>
                <template #label>
                    <span class="custom-tabs-label">
                    <i class="fa fa-user p-3 text-success" aria-hidden="true"></i>
                    <span>Client Information</span>
                    </span>
                </template>
                <div class="mt-2 mb-4 row justify-content-between " v-if="data.client">
                    <div class="card col-md-4  ">
                        <div class="card-body" >
                            <h5 class="card-title ">Client Information</h5>
                            <p class="card-text"><strong>Name:</strong> {{ data.client.name }}</p>
                            <p class="card-text"><strong>Phone:</strong> {{ data.client.phone }}</p>
                            <p class="card-text"><strong>Email:</strong> {{ data.client.email }}</p>
                            <p class="card-text"><strong>Business Name:</strong> {{ data.client.businessName }}</p>
                            <p class="card-text"><strong>Created At:</strong> {{  dateTime(data.client.created_at) }}</p>
                            <p class="card-text"><strong>Sms Balance:</strong> Unavailable</p>
                        </div>
                    </div>
                    <div class="card col-md-4 ">
                        <div class="card-body" >
                            <h6 class="card-title text-center">SMS Sent for the Past 6 Months</h6>
                            <table class="table table-responsive ">
                                <thead>
                                    <tr>
                                    <th class="text-center">Month</th>
                                    <th class="text-center">Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(smsCount, index) in data.smscounts" :key="index">
                                        <td class="text-center">{{ smsCount.month_name }}</td>
                                        <td class="text-center">{{ smsCount.count }}</td>
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card col-md-4">
                        <div class="card-body" >
                            <h6 class="card-title text-center">Emails Sent for the Past 6 Months</h6>
                            <table class="table table-responsive ">
                                <thead>
                                    <tr>
                                    <th class="text-center">Month</th>
                                    <th class="text-center">Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(mailCount, index) in data.mailcounts" :key="index">
                                        <td class="text-center">{{ mailCount.month_name }}</td>
                                        <td class="text-center">{{ mailCount.count }}</td>
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </el-tab-pane>

            <el-tab-pane>
                <template #label>
                    <span class="custom-tabs-label">
                    <i class="fa fa-cogs p-3 text-success" aria-hidden="true"></i>
                    <span>Sms Parameters</span>
                    </span>
                </template>
                <div class="card-body col-md-6">
            <el-form
              ref="paramFormRef"
              :model="paramForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm"
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
              >
              <el-form-item label="Sender Id" prop="sender_id">
                <el-input v-model="paramForm.sender_id" />
              </el-form-item>
              <el-form-item label="API Key" prop="api_key">
                <el-input v-model="paramForm.api_key" />
              </el-form-item>
              <el-form-item label="Partner Id" prop="partner_id">
                <el-input v-model="paramForm.partner_id" />
              </el-form-item>

              <div class="text-center">
                <el-button
                    type="success"
                    class="my-4 mb-2"
                    :loading="isLoading"
                    @click.prevent="submitForm(paramFormRef)"
                >Save</el-button >
              </div> 
              </el-form>
          </div>
            </el-tab-pane>

            <el-tab-pane label="System Users">
                <template #label>
                    <span class="custom-tabs-label">
                    <i class="fa fa-users p-3 text-success" aria-hidden="true"></i>
                    <span>System Users</span>
                    </span>
                </template>
                <users-table
                    v-if="users"
                    :users="users"
                    :refresh="fetchUsers"
                    :current_page="current_page2"
                    :total_pages="total_pages2"
                    :per_page="per_page2"
                    />
            </el-tab-pane>


            <el-tab-pane label="Activity Logs">
                <template #label>
                    <span class="custom-tabs-label">
                    <i class="fa fa-tasks p-3 text-success" aria-hidden="true"></i>
                    <span>Activity Logs</span>
                    </span>
                </template>
                    <logs-table
                    v-if="logs"
                    :logs="logs"
                    :refresh="fetchLogs"
                    :current_page="current_page"
                    :total_pages="total_pages"
                    :per_page="per_page"
                    
                    />
                  
            </el-tab-pane>

            <el-tab-pane label="Login Statistics">
                <template #label>
                    <span class="custom-tabs-label">
                    <i class="fa fa-chart-bar p-3 text-success" aria-hidden="true"></i>
                    <span>Login Statistics</span>
                    </span>
                </template>
                <div class="row" v-if="data.client">
                    <div class="card col-md-6">
                        <div class="card-body" >
                            <h6 class="card-title text-center mb-5">Login Count for the Past 6 Months</h6>
                            <table class="table table-responsive ">
                                <thead>
                                    <tr>
                                    <th class="text-center">Month</th>
                                    <th class="text-center">Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(loginCount, index) in data.logincounts" :key="index">
                                        <td class="text-center">{{ loginCount.month_name }}</td>
                                        <td class="text-center">{{ loginCount.count }}</td>
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card col-md-6">
                      <div class="card-body" >
                        <h6 class="card-title text-center">Login Stats</h6>
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="timeline">
                                    <div class="timeline-event">
                                    <div class="timeline-circle"> {{ data.today }}</div>
                                    <p style="margin-left:45%; margin-top:-6%;" class="">Today</p>
                                    </div>
                                
                                    <div class="timeline-event">
                                    <div class="timeline-circle">{{ data.thisweek }}</div>
                                    <p style="margin-right:50%; margin-top:-6%;">This Week</p>
                                    </div>
                                    <div class="timeline-event">
                                    <div class="timeline-circle"> {{ data.thismonth }}</div>
                                    <p style="margin-left:50%; margin-top:-6%;" >This Month</p>
                                    </div>
                                    <div class="timeline-event">
                                    <div class="timeline-circle">{{ data.thisyear }}</div>
                                    <p style="margin-right:50%; margin-top:-6%;">This Year</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                  </div>
                </div>
            </el-tab-pane>
        </el-tabs> 

    </section>
</template>

<script>
import { viewClient, fetchClientLogs, fetchClientUsers,  smsParam } from '@/api/admin/clients';
import { useStore } from 'vuex';
import { ref, onMounted, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import moment from "moment";
import LogsTable from '@/views/components/tables/LogsTable.vue';
import UsersTable from "@/views/components/tables/UsersTable.vue"
import { ElNotification } from "element-plus";


export default {
    components: {
        LogsTable,
        UsersTable
      },
      
   
    setup(){

        const per_page = ref(0);
        const current_page = ref(0);
        const total_pages = ref(0);

        const per_page2 = ref(0);
        const current_page2 = ref(0);
        const total_pages2 = ref(0);

        const data = ref([]);
        const isLoading = ref(false);
        const paramFormRef = ref(null);
        const paramForm =reactive({
            api_key: data.value.client?.smsparams?.apiKey,
            sender_id: data.value.client?.smsparams?.senderId,
            partner_id: data.value.client?.smsparams?.partnerId
        })
        const logs = ref([])
        const users = ref([]);

        const rules = reactive({
        api_key: [
            {
            required: true,
            message: "Please input api key",
            trigger:["blur", "change"],
            },
        ],
        sender_id: [
            {
            required: true,
            message: "Please input sender id",
            trigger: ["blur", "change"],
            },
        ],
        partner_id: [
            {
            required: true,
            message: "Please input partner id",
            trigger: ["blur", "change"],
            },
        ],
        });

        const route = useRoute();
        
        const store = useStore();
        const id = ref(route.params.id);
        const fetchData = async () => {
            try{
                store.commit('isLoading', true)
                const response = await viewClient(id.value);
                console.log('response is',response.data)
                data.value = response.data;
                console.log('response is',data.value)
            
            } catch (error) {
            console.log(error);
            } finally {
            store.commit('isLoading', false)
           
            }
        };

        const fetchLogs = async (filter, page) => {
            let entity_params = {};
            try {
              if (filter == undefined) {
                entity_params = ref({});
              } else {
                entity_params = filter;
              }
              store.commit('isLoading', true)
              const response = await fetchClientLogs(entity_params, page, id.value);
              logs.value = response.data;
              console.log('response is',logs.value)
    
              const total_records = response.data.total || 0;
              per_page.value = response.data.per_page;
              current_page.value = response.data.current_page;
              
              if (per_page.value > 0 && total_records > 0) {
                  total_pages.value = Math.ceil(total_records / per_page.value);
              }
            } catch (error) {
              console.log(error);
            } finally {
              store.commit('isLoading', false)
            }
          };

          const fetchUsers = async (filter, page) => {
            let entity_params = {};
            try {
              if (filter == undefined) {
                entity_params = ref({});
              } else {
                entity_params = filter;
              }
              store.commit('isLoading', true)
              const response = await fetchClientUsers(entity_params, page, id.value);
              users.value = response.data;
              console.log('response is',users.value)
    
              const total_records = response.data.total || 0;
              per_page2.value = response.data.per_page;
              current_page2.value = response.data.current_page;
              
              if (per_page2.value > 0 && total_records > 0) {
                  total_pages2.value = Math.ceil(total_records / per_page2.value);
              }
            } catch (error) {
              console.log(error);
            } finally {
              store.commit('isLoading', false)
            }
          };


        const dateTime = (value) => {
            return moment(value).format("Do MMM YYYY HH:mm:ss");
        };

        onMounted(()=>{
            fetchData()
            fetchLogs()
            fetchUsers()
        })

        watch(data, (newData) => {
            console.log('data.value changed:', newData);
            paramForm.api_key = newData.client?.smsparams?.apiKey;
            paramForm.sender_id = newData.client?.smsparams?.senderId;
            paramForm.partner_id = newData.client?.smsparams?.partnerId;
          
        });

        const submitForm = async (formEl) => {
            console.log('clicked');
            if (!formEl) return;
            await formEl.validate((valid, fields) => {
                if (valid) {
                isLoading.value = true
                const formData = new FormData();
                formData.append("api_key", paramForm.api_key);
                formData.append("sender_id", paramForm.sender_id);
                formData.append("partner_id", paramForm.partner_id);
                formData.append("client_id", id.value);

          smsParam(formData)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  ElNotification({
                    title: "Success",
                    message: response.data.message,
                    type: "success",
                  });
                }else if (response.data.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.data.error,
                    type: 'error',
                  });
              }  else {
                  Object.values(response.data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),
              )
              .finally(() => {
                isLoading.value = false
          });
              } else {
                console.log("error submit!", fields);
              }
            });
          };

        return {
            data,
            dateTime,
            paramForm,
            paramFormRef,
            rules,
            per_page,
            total_pages,
            current_page,
            logs,
            fetchData,
            fetchLogs,
            fetchUsers,
            users,
            per_page2,
            total_pages2,
            current_page2,
            submitForm,
            isLoading
        }
    }

}

</script>
<style scoped>
    .card {
        border: none;
        border-radius: 1px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        transition: transform 0.2s;
    }

    .card:hover {
        transform: scale(1.02);
        z-index:1000;
        border-radius: 1px;
    }

    .card-body {
        padding: 30px;
    }

    .card-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 15px;
    }

    .card-text {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .timeline {
      position: relative;
      margin: 20px 0;
      padding: 40px 0;
    }

    .timeline::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      background-color: #555;
      transform: translateX(-50%);
    }

    .timeline-event {
      position: relative;
      margin-bottom: 20px;
      text-align: center;
    }

    .timeline-circle {
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      background-color: #007bff;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }

    
    
    </style>