<template>
    <div>
      <el-menu>
        <p class="ms-2 sider text-sm">Home Page</p>
      <el-menu-item
        :class="{ 'is-active': activeMenuItem === '1' }"
        index="1" >
        <i class="fa fa-th text-success text-sm opacity-10 me-2"></i>
        <router-link to="/admin-dashboard" class="text-sm"
        >Dashboard</router-link>
      </el-menu-item>

      <p class="ms-2 sider text-sm">Users</p>
        <el-sub-menu
          index="2"
      >
        <template #title>
          <i class="fa fa-users text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
          <span>Clients</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/clients" class="text-sm">
            <el-menu-item index="2-1">
              <i class="fa fa-list-ul text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              Client List
            </el-menu-item>
            </router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <p class="ms-2 sider text-sm">Communication</p>
        <el-sub-menu
          index="3"
      >
        <template #title>
          <i class="fa fa-comments-o text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
          <span>Sms</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/admin-sent-box" class="text-sm">
            <el-menu-item index="3-1">
              <i class="fa fa-check-circle text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              Sent Messages
            </el-menu-item>
            </router-link>
          <router-link to="/admin-sms-out-box" class="text-sm">
            <el-menu-item index="3-2">
              <i class="fa fa-exclamation-circle text-sm opacity-10 text-danger me-2" aria-hidden="true"></i>
              Failed Messages
            </el-menu-item>
            </router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu
          index="4"
      >
        <template #title>
          <i class="fa fa-envelope-o text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
          <span>Emails</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/admin-email-sent-box" class="text-sm">
            <el-menu-item index="4-1">
              <i class="fa fa-check-circle text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              Sent Messages</el-menu-item></router-link>
          <router-link to="/admin-email-out-box" class="text-sm">
            <el-menu-item index="4-2">
              <i class="fa fa-exclamation-circle text-sm opacity-10 text-danger me-2" aria-hidden="true"></i>
              Failed Messages</el-menu-item></router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu
          index="5"
      >
        <template #title>
          <i class="fa fa-group text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
          <span>Group Emails</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/admin-email-group-sent-box" class="text-sm">
            <el-menu-item index="5-1">
              <i class="fa fa-check-circle text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              Sent Messages</el-menu-item></router-link>
          <router-link to="/admin-email-group-out-box" class="text-sm">
            <el-menu-item index="5-2">
              <i class="fa fa-exclamation-circle text-sm opacity-10 text-danger me-2" aria-hidden="true"></i>
              Failed Messages</el-menu-item></router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu
          index="6"
      >
        <template #title>
          <i class="fa fa-user-friends text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
          <span>Group Sms</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/admin-sms-group-sent-box" class="text-sm">
            <el-menu-item index="6-1">
              <i class="fa fa-check-circle text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              Sent Messages</el-menu-item></router-link>
          <router-link to="/admin-sms-group-out-box" class="text-sm">
            <el-menu-item index="6-2">
              <i class="fa fa-exclamation-circle text-sm opacity-10 text-danger me-2" aria-hidden="true"></i>
              Failed Messages</el-menu-item></router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <p class="ms-2 sider text-sm">More Options</p>
      <el-sub-menu
          index="7"
      >
        <template #title>
          <i class="fa fa-headphones text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
          <span>Support</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/admin-tickets" class="text-sm">
            <el-menu-item index="7-1">
              <i class="fa fa-ticket text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              View Tickets</el-menu-item></router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <el-menu-item index="8">
        <i class="fa fa-list-alt text-success text-sm opacity-10 me-2"></i>
        <router-link to="/logs" class="text-sm"
          >Activity Logs</router-link
        >
      </el-menu-item>
      <el-menu-item index="9" >
        <i class="fa fa-user-o text-success text-sm opacity-10 me-2"></i>
        <router-link to="/profile" class="text-sm"
          >Profile</router-link
        >
      </el-menu-item>


    </el-menu>
</div>
</template>

<script>

</script>

<style scoped>
/* Add the CSS code to position the element at the bottom of the page */
.sidenav-foot {
    position: relative;
    bottom: -17%;
    left: 0%;
}
@media (min-width: 768px) {
  .sidenav-foot {
    position: relative;
    bottom: -26%;
    left: 0%;
}
}
.custom-span {
  border-radius: 50%;
  background-color: red;
  padding: 0; /* Remove padding to let the height be controlled by line-height */
  color: white;
  font-size: 12px; /* Adjust the font size as needed */
  line-height: 50%; /* Set the line-height to 50% of the containing div */
  height: 50%; /* Set the height to 100% of the containing div */
  
}
.sider{
  color:black;
  margin-bottom:-5px;
  margin-top:15px;
}


</style>
