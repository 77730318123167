<template>
    <div class="container-fluid py-4">
        <div class="text-end mb-3 px-2">
            
           </div>
      <div class="row">
        <div class="col-12">
          <p @click="back" class="text-dark text-capitalize ps-3 py-2 pointer"><i class="fas fa-arrow-left  me-1" aria-hidden="true"></i>Go back</p>
          <div class="card my-4">
            <div class="">
              <div class="">
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <h6 class="text-dark text-capitalize ps-3 py-2 text-center">Sms Report</h6>
              <div class="table-responsive p-0 " v-if="sms.data && sms.data.length > 0">
                <table class="table align-items-center mb-0">
                  <thead :style="{ background: $themeColor() }" >
                    <tr >
                        <th
                            v-for="(column, index) in Object.keys(sms.data[0])"
                            :key="index"
                            class="text-uppercase text-white text-xxs font-weight-bolder opacity-7"
                        >
                            {{ column }}
                        </th>
                    </tr>
                  </thead>
                  <tbody id="">
                    <tr v-for="(item, index) in sms.data" :key="index">
                        <td v-for="(value, key) in item" :key="key">
                            <!-- Add your custom rendering logic here if needed -->
                            <p class="text-secondary text-xs font-weight-bold mb-0 px-2 truncate-text">{{ value }}</p>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <el-empty :image-size="200" description="No Data" />
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pag" v-if="sms.data && sms.data.length > 0">
        <!-- <pagination
            :totalPages="total_pages"
            :perPage="per_page"
            :currentPage="current_page"
            @pagechanged="onPageChange"
        /> -->
    </div>
    </template>
    <script>
    //  import Pagination from '@/components/comms/Pagination.vue';
     import { ref } from "vue";
     import moment from "moment";
     const filter_params = ref({});
     const date = ref('')
    
     
    export default {
      name: 'SentBox',
      props: {
        sms: {
        type: Object,
        required: true,
        },
        total_pages: {
        required: true,
        },
        per_page: {
        required: true,
        },
        current_page: {
        required: true,
        },
        refresh: {
        type: Function,
        required: true,
        }
     },
      components: {
        // Pagination,
        
      },
     
    
      setup(props, {emit}){
       
        const onPageChange = (page) => {
          // props.current_page = page
        console.log(page)
         
        };

          const getInitials = (name) => {
            if (name) {
                const nameSplit = name?.split(" ");
                const firstInitials = nameSplit[0]?.charAt(0);
                const secondInitials = nameSplit[1]?.charAt(0);

                return (
                    (firstInitials ? firstInitials : "") +
                    (secondInitials ? secondInitials : "")
                );
                }
                return "";
                };

          const dateTime = (value) => {
              return moment(value).format("Do MMM YYYY HH:mm:ss");
          };
          const _filterCallBack = (filter_data) => {
            filter_params.value = filter_data;
            props.refresh(filter_params, 0);
            };

            const back = ()=>{
              emit('back')
            }
            
          return {
              _filterCallBack,
              filter_params,
              onPageChange,
              getInitials,
              dateTime,
              date,
              back
              

          }
    },
     
    }
    </script>
    <style scoped>
    .pag{
      align-content: center;
    }
    @media (min-width: 768px) {
      .pag{
        margin-left: 35%;
      }
    }
    .truncate-text {
        overflow: hidden;
        white-space: pre-wrap;
        max-width: 150px; 
    }
  .pointer{
    cursor:pointer;
  }
    </style>