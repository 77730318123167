<template>
    <div class="container-fluid py-4">
        <div class="px-3">
           <p>To raise a ticket, enter the description and image if any. Monitor the progress of your ticket by checking status. The status will change to closed once the issue is resolved</p>
        </div>
        <div class="text-end mb- px-2 topup">
            <el-button
                 :style="{ background: $themeColor() }"
                class="text-white"
                @click="addUser"
                >
                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Raise Ticket
            </el-button>      
        </div>
        <div class="d-flex justify-content-center mb-3 px-2" v-if="tickets.data && tickets.data.length == 0">
            <el-empty :image-size="200" description="All tickets will appear here!" />
        </div>
        <tickets-table
        v-else
        :tickets="tickets"
        :refresh="fetchData"
        :current_page="current_page"
        :total_pages="total_pages"
        :per_page="per_page"
        />
    </div>
    
    <tickets-form
    v-if="showModal"
    :refresh="fetchData"
    @close-modal="showModal = false"/>
</template>
<script>
import TicketsForm from "@/components/Modals/TicketsForm.vue"
import TicketsTable from "@/views/components/tables/TicketsTable.vue"
import {useStore} from 'vuex'
import {fetchTickets} from "@/api/ticket"
import { ref, onMounted, reactive } from "vue"

export default {
    components: {
        TicketsForm,
        TicketsTable
    },

    setup(){
        const tickets = ref([]);
        const per_page = ref(0);
        const current_page = ref(0);
        const total_pages = ref(0);
 
        const showModal = ref(false)

        const addUser = ()=>{
            showModal.value = true
        }

        const store = useStore()
        const fetchData = async (filter, page) => {
            console.log('fetching')
            // isLoading.value = true;
            let entity_params = {};
            try {
            if (filter == undefined) {
                entity_params = ref({});
            } else {
                entity_params = filter;
            }
            store.commit('isLoading', true)
            const response = await fetchTickets(entity_params, page);
            console.log('response is',response.data)
            tickets.value = response.data;
            console.log('response is',tickets.value)

            const total_records = response.data.total || 0;
            per_page.value = response.data.per_page;
            current_page.value = response.data.current_page;
            
            if (per_page.value > 0 && total_records > 0) {
                total_pages.value = Math.ceil(total_records / per_page.value);
            }
            
            } catch (error) {
            console.log(error);
            } finally {
            store.commit('isLoading', false)
            }
        };
        const paginate = reactive({
                current_page: current_page.value || 1,
                total_pages: total_pages.value,
                per_page: per_page.value,
            });

        onMounted(()=>{
            fetchData()
        })
        return {
            showModal,
            addUser,
            fetchData,
            tickets,
            paginate,
            per_page,
            total_pages,
            current_page
        }
    }
}
</script>
<style scoped>
.topup{
  margin-right:5px;
}
</style>