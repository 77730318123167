<template>
    <div class="container-fluid py-4">
        <div class="">
        </div>
        <div class="text-end mb- px-2 topup">
            <el-button
               :style="{ background: $themeColor() }"
                class="text-white"
                @click="addGroup"
                >
                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Add Group
            </el-button>      
        </div>
        <div class="d-flex justify-content-center mb-3 px-2" v-if="groups.data && groups.data.length == 0">
            <el-empty :image-size="200" description="All groups will appear here!" />
        </div>
        <groups-table
        v-else
        :groups="groups"
        :refresh="fetchData"
        :current_page="current_page"
        :total_pages="total_pages"
        :per_page="per_page"
        />
    </div>
    
    <groups-form
    v-if="showModal"
    :refresh="fetchData"
    @close-modal="showModal = false"/>
</template>
<script>
import GroupsForm from "@/components/Modals/group-sms/GroupsForm.vue"
import GroupsTable from "@/views/components/tables/group-sms/GroupsTable.vue"
import {useStore} from 'vuex'
import {fetchGroup} from "@/api/groups/sms"
import { ref, onMounted, reactive } from "vue"

export default {
    components: {
        GroupsForm,
        GroupsTable
    },

    setup(){
        const groups = ref([]);
        const per_page = ref(0);
        const current_page = ref(0);
        const total_pages = ref(0);
 
        const showModal = ref(false)

        const addGroup = ()=>{
            showModal.value = true
        }

        const store = useStore()
        const fetchData = async (filter, page) => {
            console.log('fetching')
            // isLoading.value = true;
            let entity_params = {};
            try {
            if (filter == undefined) {
                entity_params = ref({});
            } else {
                entity_params = filter;
            }
            store.commit('isLoading', true)
            const response = await fetchGroup(entity_params, page);
            console.log('response is',response)
            groups.value = response.data;
            console.log('response is',groups.value)

            const total_records = response.data.total || 0;
            per_page.value = response.data.per_page;
            current_page.value = response.data.current_page;
            
            if (per_page.value > 0 && total_records > 0) {
                total_pages.value = Math.ceil(total_records / per_page.value);
            }
            
            
            
            } catch (error) {
            console.log(error);
            } finally {
            store.commit('isLoading', false)
            }
        };
        const paginate = reactive({
                current_page: current_page.value || 1,
                total_pages: total_pages.value,
                per_page: per_page.value,
            });

        onMounted(()=>{
            fetchData()
        })
        return {
            showModal,
            addGroup,
            fetchData,
            groups,
            paginate,
            per_page,
            total_pages,
            current_page
        }
    }
}
</script>
<style scoped>
.topup{
  margin-right:5px;
}
</style>