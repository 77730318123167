import axios from "axios";
const logs_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=log:like;device:like;slug:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.name)
        url = url + "&name=" + filter.value.customer_name;

    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;

    url = url.replace("fetch-logs?&", "fetch-logs?");

    return url;
};

export const fetchLogs = async(filter, page) => {
    let response = await axios
        .get(logs_url("/fetch-logs?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};


