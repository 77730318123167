import axios from "axios";

export const grantPermission = async(url) => {
    let response = await axios
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};


export const fetchRole = async() => {
    let response = await axios
        .get('fetch-permissions')
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchRolePermission = async(id) => {
    let response = await axios
        .get(`fetch-role-permission?id=${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updatePermissionRole = async(url) => {
    let response = await axios
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeRole = async(id) => {
    let response = await axios
        .delete(`delete-role/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

