<template>
  <div
  >
      <el-menu
      >
      <p class="ms-2 sider text-sm">Home Page</p>
      <el-menu-item
      @click="handleMenuItemClick('2')"
        :class="{ 'is-active': activeMenuItem === '2' }"
       index="2-20" >
        <i :style="{ color: $themeColor() }" class="fa fa-th  text-sm opacity-10 me-2"></i>
        <router-link to="/dashboard" class="text-sm"
          >Dashboard</router-link
        >
      </el-menu-item>
      <p class="ms-2 sider text-sm">Our Services</p>
        <el-sub-menu
       
          index="1"
      >
        <template #title>
          <i :style="{ color: $themeColor() }" class="fa fa-comments-o text-sm opacity-10  me-2" aria-hidden="true"></i>
          <span>Sms</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/sent-box" class="text-sm">
            <el-menu-item index="1-1">
              <i class="fa fa-check-circle text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              Sent Messages
            </el-menu-item>
            </router-link>
          <router-link to="/sms-out-box" class="text-sm">
            <el-menu-item index="2-1">
              <i class="fa fa-exclamation-circle text-sm opacity-10 text-danger me-2" aria-hidden="true"></i>
              Failed Messages
            
            </el-menu-item>
            </router-link>
          <router-link to="/send-single-sms" v-if="hasPermission('send.singlesms')">
            <el-menu-item index="3-1">
              <i class="fa fa-comment text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Send Single Sms
            </el-menu-item>
            </router-link>
            <router-link to="/send-bulk-sms" v-if="hasPermission('send.bulksms')">
            <el-menu-item index="4-1">
              <i class="fa fa-comments text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Send Bulk Sms
            </el-menu-item>
            </router-link>
            <router-link to="/send-custom-sms" v-if="hasPermission('send.customsms')">
            <el-menu-item index="5-1">
              <i class="fa fa-paper-plane  text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Send Custom Sms
            </el-menu-item>
            </router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu
          index="3"
          @click="handleMenuItemClick('3')"
        :class="{ 'is-active': activeMenuItem === '3' }"
      >
        <template #title>
          <i :style="{ color: $themeColor() }" class="fa fa-envelope-o text-sm opacity-10  me-2" aria-hidden="true"></i>
          <span>Emails</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/email-sent-box" class="text-sm">
            <el-menu-item index="5-3">
              <i class="fa fa-check-circle text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              Sent Messages</el-menu-item></router-link>
          <router-link to="/email-out-box" class="text-sm">
            <el-menu-item index="1-3">
              <i class="fa fa-exclamation-circle text-sm opacity-10 text-danger me-2" aria-hidden="true"></i>
              Failed Messages</el-menu-item></router-link>
          <router-link to="/send-single-email" v-if="hasPermission('send.singleemail')">
            <el-menu-item index="2-3">
              <i class="fa fa-envelope text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Send Single Email
            </el-menu-item>
            </router-link>
            <router-link to="/send-bulk-email" v-if="hasPermission('send.bulkemail')">
            <el-menu-item index="3-3">
              <i class="fa fa-envelope-open text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Send Bulk Email
            </el-menu-item>
            </router-link>
            <router-link to="/send-custom-email" v-if="hasPermission('send.customemail')">
            <el-menu-item index="4-3">
              <i class="fa fa-paper-plane text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Send Custom Email
            </el-menu-item>
            </router-link>
            <router-link to="/sender-email-settings" >
            <el-menu-item index="51-3">
              <i class="fa fa-cog text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Manage Sender Emails
            </el-menu-item>
            </router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu
          index="12"
          @click="handleMenuItemClick('12')"
        :class="{ 'is-active': activeMenuItem === '12' }"
      >
        <template #title>
          <i :style="{ color: $themeColor() }" class="fa fa-group text-sm opacity-10  me-2" aria-hidden="true"></i>
          <span>Group Emails</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/email-group-sent-box" class="text-sm">
            <el-menu-item index="5-12">
              <i class="fa fa-check-circle text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              Sent Messages</el-menu-item></router-link>
          <router-link to="/email-group-out-box" class="text-sm">
            <el-menu-item index="1-12">
              <i class="fa fa-exclamation-circle text-sm opacity-10 text-danger me-2" aria-hidden="true"></i>
              Failed Messages</el-menu-item></router-link>
          <router-link to="/send-group-email" v-if="hasPermission('send.groupemail')">
            <el-menu-item index="2-12">
              <i class="fa fa-paper-plane text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Send Email
            </el-menu-item>
            </router-link>
            <router-link to="/email-group-list" v-if="hasPermission('manage.emailgroup')">
            <el-menu-item index="3-12">
              <i class="fa fa-envelope-open text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Group List
            </el-menu-item>
            </router-link>
            <router-link to="/email-group-members"  v-if="hasPermission('manage.emailgroup')">
            <el-menu-item index="4-12">
              <i class="fa fa-users text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Group Members
            </el-menu-item>
            </router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu
          index="13"
          @click="handleMenuItemClick('13')"
        :class="{ 'is-active': activeMenuItem === '13' }"
      >
        <template #title>
          <i :style="{ color: $themeColor() }" class="fa fa-user-friends text-sm opacity-10 me-2" aria-hidden="true"></i>
          <span>Group Sms</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/sms-group-sent-box" class="text-sm">
            <el-menu-item index="5-13">
              <i class="fa fa-check-circle text-sm opacity-10 text-success me-2" aria-hidden="true"></i>
              Sent Messages</el-menu-item></router-link>
          <router-link to="/sms-group-out-box" class="text-sm">
            <el-menu-item index="1-13">
              <i class="fa fa-exclamation-circle text-sm opacity-10 text-danger me-2" aria-hidden="true"></i>
              Failed Messages</el-menu-item></router-link>
          <router-link to="/send-group-sms" v-if="hasPermission('send.groupsms')">
            <el-menu-item index="2-13">
              <i class="fa fa-paper-plane text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Send Sms
            </el-menu-item>
            </router-link>
            <router-link to="/sms-group-list" v-if="hasPermission('manage.smsgroup')">
            <el-menu-item index="3-13">
              <i class="fa fa-envelope text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Group List
            </el-menu-item>
            </router-link>
            <router-link to="/sms-group-members"  v-if="hasPermission('manage.smsgroup')">
            <el-menu-item index="4-13">
              <i class="fa fa-users text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Group Members
            </el-menu-item>
            </router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu
          index="8"
          @click="handleMenuItemClick('5')"
        :class="{ 'is-active': activeMenuItem === '5' }"
      >
        <template #title>
          <i :style="{ color: $themeColor() }" class="fa fa-line-chart text-sm opacity-10 me-2" aria-hidden="true"></i>
          <span>Reports</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/sms-report" class="text-sm" v-if="hasPermission('manage.smsreport')">
            <el-menu-item index="1-8">
              <i class="fa fa-comments-o text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Sms Report</el-menu-item></router-link>
          <router-link to="/emails-report" v-if="hasPermission('manage.emailreport')">
            <el-menu-item index="2-8">
              <i class="fa fa-envelope text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
             Emails Report
            </el-menu-item>
            </router-link>
          <router-link to="/sms-groups-report" class="text-sm" v-if="hasPermission('manage.groupsmsreport')">
          <el-menu-item index="3-8">
            <i class="fa fa-comments text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
            Group Sms Report</el-menu-item></router-link>

            <router-link to="/email-groups-report" v-if="hasPermission('manage.groupemailreport')">
            <el-menu-item index="4-8">
              <i class="fa fa-envelope-o text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
            Group Emails Report
            </el-menu-item>
            </router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <p class="ms-2 sider text-sm">More Options</p>
      <el-sub-menu
      v-if="hasPermission('manage.systemusers')"
          index="5"
          @click="handleMenuItemClick('5')"
        :class="{ 'is-active': activeMenuItem === '5' }"
      >
        <template #title>
          <i :style="{ color: $themeColor() }" class="fa fa-users text-sm opacity-10 me-2" aria-hidden="true"></i>
          <span>System Users</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/roles-and-permissions" class="text-sm">
            <el-menu-item index="1-5">
              <i class="fa fa-lock text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Roles and Permissions</el-menu-item></router-link>
          <router-link to="/users">
            <el-menu-item index="2-5">
              <i class="fa fa-user-plus text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Users
            </el-menu-item>
            </router-link>
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu
          index="17"
          @click="handleMenuItemClick('17')"
        :class="{ 'is-active': activeMenuItem === '17' }"
      >
        <template #title>
          <i :style="{ color: $themeColor() }" class="fa fa-headphones text-sm opacity-10 me-2" aria-hidden="true"></i>
          <span>Support</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/tickets" class="text-sm">
            <el-menu-item index="1-17">
              <i class="fa fa-ticket text-sm opacity-10 text-secondary me-2" aria-hidden="true"></i>
              Raise a Ticket</el-menu-item></router-link>
        </el-menu-item-group>
      </el-sub-menu>
     
      <el-menu-item index="11">
        <i :style="{ color: $themeColor() }" class="fa fa-list-alt text-sm opacity-10 me-2"></i>
        <router-link to="/logs" class="text-sm"
          >Activity Logs</router-link
        >
      </el-menu-item>
      <el-menu-item index="7" >
        <i :style="{ color: $themeColor() }" class="fa fa-user-o text-sm opacity-10 me-2"></i>
        <router-link to="/profile" class="text-sm"
          >Profile</router-link
        >
      </el-menu-item>
      </el-menu>
 
  </div>
</template>
<script>
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      activeMenuItem: null,
      userPermissions: storedPermissions || [],
    };
  },
  components: {
   
    
    
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    handleMenuItemClick(index) {
      if (this.activeMenuItem === index) {
        this.activeMenuItem = null; // Collapse the active menu item
      } else {
        this.activeMenuItem = index; // Expand the clicked menu item
      }
    },
    hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
  },

 

};
</script>
<style scoped>
/* Add the CSS code to position the element at the bottom of the page */
.sidenav-foot {
    position: relative;
    bottom: -17%;
    left: 0%;
}
@media (min-width: 768px) {
  .sidenav-foot {
    position: relative;
    bottom: -26%;
    left: 0%;
}
}
.custom-span {
  border-radius: 50%;
  background-color: red;
  padding: 0; /* Remove padding to let the height be controlled by line-height */
  color: white;
  font-size: 12px; /* Adjust the font size as needed */
  line-height: 50%; /* Set the line-height to 50% of the containing div */
  height: 50%; /* Set the height to 100% of the containing div */
  
}
.sider{
  color:black;
  margin-bottom:-5px;
  margin-top:15px;
}


</style>

