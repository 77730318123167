<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-2 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 style="color: #ED6D08;" class="font-weight-bolder  text-center ">
                    SIGN UP
                  </h3>
                </div>
                <div class="card-body">
                  <el-form
              ref="loginFormRef"
              :model="loginForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm"
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
              >
              <el-form-item label="Name" prop="name">
                <el-input v-model="loginForm.name" />
              </el-form-item>
              <el-form-item label="Email" prop="email">
                <el-input v-model="loginForm.email" />
              </el-form-item>
              <div class="col-md-12 mb-3">
                <label class="el-form-item__label">Mobile Number</label>
                <vue-tel-input
                    v-model="loginForm.phone"
                    :value="loginForm.phone"
                    @validate="checkMobileNumber"
                    v-bind="phoneBindingProps"
                ></vue-tel-input>
              </div>
              <el-form-item label="Business Name" prop="businessName">
                <el-input v-model="loginForm.businessName" />
              </el-form-item>
              <el-form-item label="Password" prop="password">
                <el-input
                  v-model="loginForm.password"
                  type="password"
                  :show-password="true"
                />
              </el-form-item>
              <el-form-item label="Confirm Password" prop="confirm_password">
                <el-input
                  v-model="loginForm.confirm_password"
                  type="password"
                  :show-password="true"
                />
              </el-form-item>

              <div class="text-center">
                <el-button
                :style="'background:#116FB7; color:white;'"
                    class="my-4 mb-2"
                    :loading="isLoading"
                    @click.prevent="submitForm(loginFormRef)"
                >Sign Up</el-button >
              </div> 
              <p class="text-sm mt-3 mb-0">
                  Already have an account?
                  <router-link
                    :to="{ name: 'login' }"
                    :style="'color:#ED6D08'"
                    class="font-weight-bold"
                  >
                    Sign In
                  </router-link>
                </p>
              </el-form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/send.jpg') +
                      ')',
                  }"
                >
                <span style="background: #116FB7;" class="mask  opacity-6"></span>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { login } from "../api/authentication";
import { register } from "../api/authentication";
import {  reactive, ref } from "vue";
import { useRouter } from 'vue-router';
import { mapMutations } from "vuex";
import { ElNotification, ElMessage } from "element-plus";
import { h } from "vue";


export default {
  name: "LogIn",
  components: {
    AppFooter,
  },


  setup (){
    const isLoading = ref(false)
    const loginFormRef = ref(null);
    const router = useRouter();
    const loginForm =reactive({
      email: '',
      password: '',
      name: '',
      confirm_password: '',
      phone: '',
      businessName: '',
    })

    const rules = reactive({
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
      name: [
        {
          required: true,
          message: "Please input name",
          trigger: "blur",
        },
        
      ],
      businessName: [
        {
          required: true,
          message: "Please input business name",
          trigger: "blur",
        },
        
      ],
      phone: [
          {
            required: true,
            message: "Please input phone number",
            trigger: ["blur", "change"],
          },
        ],
      password: [
        {
          required: true,
          message: "Please input your password",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
      confirm_password: [
        {
          required: true,
          message: "Please input your password",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
    });
    const checkMobileNumber = (event) => {
        if (event.valid) {
          loginForm.phone = event.number;
          phoneBindingProps.styleClasses = "has-success";
        } else {
          loginForm.phone = "";
          phoneBindingProps.styleClasses = "has-danger";
        }
      };
      const phoneBindingProps = {
        mode: "international",
        styleClasses: "has-default",
        defaultCountry: "KE",
        onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
        inputOptions: {
          showDialCode: false,
          placeholder: "Mobile Number",
          name: "phone",
        },
      };

      const signIn = async()=>{
        let body = {
            grant_type: 'password',
            client_id: '4',
            client_secret: 'weii4eVviiIBNEb3S3E12xTi2I8RTlZq39WchbkJ',
            username: loginForm.email,
            password: loginForm.password
          }
          await login(body)
          .then((response) => {
            if(response.status == 200){
              console.log('response data is',response.data);
              localStorage.setItem('access_token', response.data.access_token);
              localStorage.setItem('isAuthenticated', 1);
              localStorage.setItem(
                    "permissionlist",
                    JSON.stringify(response.data.permissions)
                );
                localStorage.setItem(
                    "role",
                    response.data.role
                );
                localStorage.setItem(
                    "sender_email",
                    response.data.sender_email
                );
                localStorage.setItem(
                      "theme_color",
                      response.data.theme_color
                  );
                  localStorage.setItem(
                      "logo",
                      response.data.logo
                  );
              router.push('/dashboard');
              ElMessage({
                  message: h(
                    "i",
                    { style: "color: teal" },
                    `Welcome ${
                      response.data.user.name 
                    } to your dashboard`
                  ),
                  type: "success",
                });
            } 
          })
      }

      const submitForm = async (formEl) => {
      const message = loginForm.phone;
      if (message === '') {
        ElNotification({
          title: "Error",
          message: "Please Fill all fields",
          type: "error",
        })
        return;
      } 
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true
          const formData = new FormData();
          formData.append('name', loginForm.name)
          formData.append('email', loginForm.email)
          formData.append('businessName', loginForm.businessName)
          formData.append('phone', loginForm.phone)
          formData.append('password', loginForm.password)
          formData.append('password_confirmation', loginForm.confirm_password)
          register(formData)
              .then((response) => {
                console.log('response data is',response.data);
                if (response.data.success) {
                  signIn();
                }else if (response.data.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.data.error,
                    type: 'error',
                  });
              }  else {
                  Object.values(response.data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),
              )
              .finally(() => {
                isLoading.value = false
          });
              } else {
                console.log("error submit!", fields);
              }
            });
          };

    return {
      loginForm,
      loginFormRef,
      submitForm,
      rules,
      phoneBindingProps,
      checkMobileNumber,
      
    }
  },
  
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    
  },
  computed: {
    emailRules() {
      return [
        {
          required: true,
          message: 'Email is required',
          trigger: 'blur',
        },
        {
          type: 'email',
          message: 'Invalid email format',
          trigger: 'blur',
        },
      ];
    },
  },
};
</script>




<!--  body.classList.remove("bg-gray-100"); -->


