<template>
    <div class="container-fluid py-4">
        <div class="text-end mb- px-2 topup">
            <el-button
                :style="{ background: $themeColor() }"
                class="text-white"
                @click="addSenderEmail"
                >
                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Add Sender Email
            </el-button>      
        </div>
        <div class="d-flex justify-content-center mb-3 px-2" v-if="sender_emails.data && sender_emails.data.length == 0">
            <el-empty :image-size="200" description="All sender_emails will appear here!" />
        </div>
        <sender-emails-table
        v-else
        :sender_emails="sender_emails"
        :refresh="fetchData"
        :current_page="current_page"
        :total_pages="total_pages"
        :per_page="per_page"
        />
    </div>
    
    <sender-emails-form
    v-if="showModal"
    :refresh="fetchData"
    @close-modal="showModal = false"/>
</template>
<script>
import senderEmailsForm from "@/components/Modals/senderEmailsForm.vue"
import senderEmailsTable from "@/views/components/tables/senderEmailsTable.vue"
import {useStore} from 'vuex'
import {fetchSenderEmail} from "@/api/email";
import { ref, onMounted, reactive } from "vue"

export default {
    components: {
        senderEmailsForm,
        senderEmailsTable
    },

    setup(){
        const sender_emails = ref([]);
        const per_page = ref(0);
        const current_page = ref(0);
        const total_pages = ref(0);
 
        const showModal = ref(false)

        const addSenderEmail = ()=>{
            showModal.value = true
        }

        const store = useStore()

        const fetchData = async () => {

           try{
            store.commit('isLoading', false)
            const response = await fetchSenderEmail();
            console.log('response is',response)
            sender_emails.value = response.data;
            console.log('response is',sender_emails.value)

            const total_records = response.data.total || 0;
            per_page.value = response.data.per_page;
            current_page.value = response.data.current_page;
            
            if (per_page.value > 0 && total_records > 0) {
                total_pages.value = Math.ceil(total_records / per_page.value);
            }
            
            } catch (error) {
            console.log(error);
            } finally {
            store.commit('isLoading', false)
            }
        };
        const paginate = reactive({
                current_page: current_page.value || 1,
                total_pages: total_pages.value,
                per_page: per_page.value,
            });

        onMounted(()=>{
            fetchData()
            console.log(sender_emails.value)
        })
        return {
            showModal,
            addSenderEmail,
            fetchData,
            sender_emails,
            paginate,
            per_page,
            total_pages,
            current_page
        }
    }
}
</script>
<style scoped>
.topup{
  margin-right:5px;
}
</style>