import axios from "axios";

const clients_url = (root, filter, page) => {
    var url = root;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.business_name)
        url = url + "&business_name=" + filter.value.business_name;

   
    if (filter.value != undefined && filter.value.name)
    url = url + "&name=" + filter.value.name;

    if (filter.value != undefined && filter.value.email)
    url = url + "&email=" + filter.value.email;

    if (filter.value != undefined && filter.value.phone)
         url = url + "&phone=" + filter.value.phone;

    return url;
};

const logs_url = (root, filter, page) => {
    var url = root;
    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.name)
        url = url + "&name=" + filter.value.customer_name;

    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;

    return url;
};

export const fetchClients = async(filter, page) => {
    let response = await axios
        .get(clients_url("/clients?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const viewClient = async(id) => {
    let response = await axios
        .get(`/client/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchClientLogs = async(filter, page, id) => {
    let response = await axios
        .get(logs_url(`/client-logs/${id}?`, filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchClientUsers = async(filter, page, id) => {
    let response = await axios
        .get(logs_url(`/client-users/${id}?`, filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const smsParam = async(postBody) => {
    let response = await axios
        .post("/sms-param", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

