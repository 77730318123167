<template>
    <div class="py-4 container-fluid">
        <p @click="back" class="text-dark text-capitalize py-2 pointer"><i class="fas fa-arrow-left  me-1" aria-hidden="true"></i>Go back</p>
        <el-form
            ref="smsFormRef"
            :model="smsForm"
            :rules="rules"
            label-width="auto"
            class="demo-ruleForm bg-transparent mt-2 "
            size="large"
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
            >
            <div class="col-md-6">
                <div><h6>Role Name</h6></div>
                <el-form-item label="" prop="name">
                    <el-input v-model="smsForm.roleName" placeholder="Name" />
                </el-form-item>
            </div>
        
            <div class="mt-5"><h6>Assign Permission</h6></div>
        <div class="row">
            <div class="col-md-4">
                <el-form-item class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Send Single Sms</div>
                    <el-switch v-model="smsForm.singlesms" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Send Bulk Sms</div>
                    <el-switch v-model="smsForm.bulksms" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Send Custom Sms</div>
                    <el-switch v-model="smsForm.customsms" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Send Group Sms</div>
                    <el-switch v-model="smsForm.groupsms" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>
                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Manage Group Email Report</div>
                    <el-switch v-model="smsForm.groupemailreport" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>
            </div>

            <div class="col-md-4">
                <el-form-item class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Send Single Email</div>
                    <el-switch v-model="smsForm.singleemail" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Send Bulk Emails</div>
                    <el-switch v-model="smsForm.bulkemails" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Send Custom Emails</div>
                    <el-switch v-model="smsForm.customemails" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Send Group Email</div>
                    <el-switch v-model="smsForm.groupemail" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>
                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Manage Group Sms Report</div>
                    <el-switch v-model="smsForm.groupsmsreport" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>
            </div>

            <div class="col-md-4">
                <el-form-item class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Manage Email Report</div>
                    <el-switch v-model="smsForm.emailreport" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Manage Sms Report</div>
                    <el-switch v-model="smsForm.smsreport" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Manage System Users</div>
                    <el-switch v-model="smsForm.systemusers" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Manage Sms Groups</div>
                    <el-switch v-model="smsForm.smsgroup" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>

                <el-form-item  class="d-flex align-items-center gap-6">
                    <div style="width: 160px;">Manage Email Groups</div>
                    <el-switch v-model="smsForm.emailgroup" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                </el-form-item>
            </div>
        </div>
        <div class="text-start">
            <el-button
                 :style="{ background: $themeColor() }"
                class="my-4 mb-2 text-white"
                :loading="isLoading"
                @click.prevent="submitForm(smsFormRef)"
            >Update</el-button >
        </div>
    </el-form>
    </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElNotification } from "element-plus";
import {updatePermissionRole} from "@/api/permissions"


export default {
    props:{
        permissions: {
        type: Object,
        required: true,
        },
        
    },

    setup(props, {emit}){
       
        const isLoading = ref(false)
        const smsFormRef = ref(null)
        const smsForm = reactive({
            singlesms:props.permissions.singlesms,
            bulksms:props.permissions.bulksms,
            customsms:props.permissions.customsms,
            groupsms:props.permissions.groupsms,
            singleemail:props.permissions.singleemail,
            bulkemails:props.permissions.bulkemails,
            customemails:props.permissions.customemails,
            groupemail:props.permissions.groupemail,
            emailreport:props.permissions.emailreport,
            smsreport:props.permissions.smsreport,
            systemusers:props.permissions.systemusers,
            roleName:props.permissions.roleName,
            id:props.permissions.id,
            smsgroup:props.permissions.smsgroup,
            emailgroup:props.permissions.emailgroup,
            groupemailreport:props.permissions.groupemailreport,
            groupsmsreport:props.permissions.groupsmsreport,
        })
        
        const submitForm = async()=>{
            const role_name = smsForm.roleName;
            if (role_name === '') {
                ElNotification({
                title: "Error",
                message: "Please enter the role",
                type: "error",
                })
                return;
            } 
            isLoading.value = true
            const trueItems = Object.entries(smsForm).reduce((acc, [key, value]) => {
                if (value) {
                acc[key] = value;
                }
                return acc;
            }, {});

            let entity_params = {
                ...trueItems
            };
            let url = "update-permission-role?"
            url = url + 'roleName=' + smsForm.roleName
            url = url + '&id=' + smsForm.id
            if(entity_params.singlesms != undefined){
                url = url + '&singlesms=send.singlesms'
            }
            if(entity_params.bulksms != undefined){
                url = url + '&bulksms=send.bulksms'
            }
            if(entity_params.customsms != undefined){
                url = url + '&customsms=send.customsms'
            }
            if(entity_params.groupsms != undefined){
                url = url + '&groupsms=send.groupsms'
            }
            if(entity_params.singleemail != undefined){
                url = url + '&singleemail=send.singleemail'
            }
            if(entity_params.bulkemails != undefined){
                url = url + '&bulkemails=send.bulkemail'
            }
            if(entity_params.customemails != undefined){
                url = url + '&customemails=send.customemail'
            }
            if(entity_params.groupemail != undefined){
                url = url + '&groupemail=send.groupemail'
            }
            if(entity_params.emailreport != undefined){
                url = url + '&emailreport=manage.emailreport'
            }
            if(entity_params.smsreport != undefined){
                url = url + '&smsreport=manage.smsreport'
            }
            if(entity_params.systemusers != undefined){
                url = url + '&systemusers=manage.systemusers'
            }
            if(entity_params.smsgroup != undefined){
                url = url + '&smsgroup=manage.smsgroup'
            }
            if(entity_params.emailgroup != undefined){
                url = url + '&emailgroup=manage.emailgroup'
            }
            if(entity_params.groupemailreport != undefined){
                url = url + '&groupemailreport=manage.groupemailreport'
            }
            if(entity_params.groupemailreport != undefined){
                url = url + '&groupsmsreport=manage.groupsmsreport'
            }
            
            console.log('url is', url)
            try {
           
            await  updatePermissionRole(url)
              .then((response) => {
                console.log('response', response);
                if (response.success) {
                  ElNotification({
                    title: "Success",
                    message: response.message,
                    type: "success",
                  });
                  emit('permission-updated')
                }else if (response.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.error,
                    type: 'error',
                  });
              }  else {
                  Object.values(response.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),
              )
              .finally(() => {
                isLoading.value = false
          });
            
            } catch (error) {
            console.log(error);
            } finally {
                //
            }
        }
        const back = ()=>{
            emit('permission-updated')
        }

        return {
            smsForm,
            smsFormRef,
            isLoading,
            submitForm,
            back
        }
    }
}

</script>

<style scoped>
.pointer{
    cursor:pointer;
  }
</style>