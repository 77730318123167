import axios from "axios";

const sms_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=log:like;device:like;slug:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.customer_name)
        url = url + "&customer_name=" + filter.value.customer_name;

    if (filter.value != undefined && filter.value.user)
    url = url + "&sent_by=" + filter.value.user;

    if (filter.value != undefined && filter.value.groupSms)
    url = url + "&group=" + filter.value.groupSms;

    if (filter.value != undefined && filter.value.phone)
    url = url + "&phone=" + filter.value.phone;

    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;

    return url;
};


export const createGroup = async(postBody) => {
    let response = await axios
        .post("/create-sms-group", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const fetchGroup = async(filter, page) => {
    let response = await axios
        .get("/fetch-sms-groups?", filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchGroupMembers = async(filter, page) => {
    let response = await axios
        .get("/fetch-sms-group-members?", filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchUnpagGroupSms = async() => {
    let response = await axios
        .get('fetch-sms-unpag-groups')
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};


export const createGroupMember = async(postBody) => {
    let response = await axios
        .post("/create-sms-group-member", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const updateGroup = async(postBody) => {
    let response = await axios
        .post("/update-sms-group", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const fetchMembersGroup = async(filter, page, id) => {
    let response = await axios
        .get("/fetch-sms-members-group?id="+id, filter, page, id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeGroup = async(id) => {
    let response = await axios
        .delete(`delete-sms-group/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateGroupMember = async(postBody) => {
    let response = await axios
        .post("/update-sms-group-member", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const removeGroupMember = async(id) => {
    let response = await axios
        .delete(`delete-sms-group-member/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const sendSms = async(postBody) => {
    let response = await axios
        .post("/send-sms-group", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const fetchSuccessfulSms = async(filter, page) => {
    let response = await axios
        .get(sms_url("/fetch-success-group-sms?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchFailedSms = async(filter, page) => {
    let response = await axios
        .get(sms_url("/fetch-failed-group-sms?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const sampleDownload = async() => {
    let response = await axios
        .get('sample-member-sms', {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const importGroupMember = async(body) => {
    let response = await axios
        .post("/import-sms-group-member", body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};










