<template>
    <div class="container-fluid py-4">
        <div class="text-end mb-3 px-2">
           </div>
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div :style="{ background: $themeColor() }" class="shadow-primary border-radius-lg pt-4 pb-3">
                <h6 class="text-white text-capitalize ps-3">groups</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0 " v-if="groups.data && groups.data.length > 0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr >
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Description</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Group Members</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Created At</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                    </tr>
                  </thead>
                  <tbody id="">
                    <tr v-for="(item, index) in groups.data" :key="index" >
                          <td>
                            <div class="d-flex px-2 py-1 align-items-center">
                              <div>
                                <div :style="{ background: $themeColor() }" class="avatar avatar-sm me-3">
                                  {{
                                    getInitials(
                                        item.name
                                    ).toUpperCase()
                                  }}
                                </div>
                              </div>
                              <div>
                                <p class="text-xs font-weight-bold mb-0">{{ item.name }}</p>
                              </div>
                              </div>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">{{ item.description }}</p>
                          </td>
                          <td class="px-4">
                            <p class="text-xs font-weight-bold mb-0 px-4">{{ item.email_members.length}}</p>
                          </td>
                         
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">{{  dateTime(item.created_at) }}</span>
                          </td>
                          <td class="align-middle text-center">
                            <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                              <i class="fa fa-ellipsis-v text-secondary"></i>
                            </span>
                            <template #dropdown>
                             
                              <el-dropdown-menu>
                                <router-link :to="{ path: `/group/${item.id}` }">
                                  <el-dropdown-item
                                      class="text-secondary font-weight-bold text-xs"
                                  ><i class="fa fa-eye" aria-hidden="true"></i>
                                      View
                            </el-dropdown-item>
                                </router-link>
                              
                            <el-dropdown-item
                                class="text-secondary font-weight-bold text-xs"
                                @click="updateGroup(item)"
                            ><i class="fa fa-pen" aria-hidden="true"></i>
                                Update
                            </el-dropdown-item>
                            
                            <el-dropdown-item
                                class="text-secondary font-weight-bold text-xs"
                                @click="deleteGroup(item.id)"
                            ><i class="fa fa-trash" aria-hidden="true"></i>
                              Delete
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                          </td>
                        
                        </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                 <p class="text-center">Nothing in groups</p>
            </div>
            </div>
          </div>
        </div>
       
      </div>
        <div class="d-flex justify-content-center mb-3 px-2" v-if="groups.data && groups.data.length > 0">
            <pagination
                :totalPages="total_pages"
                :perPage="per_page"
                :currentPage="current_page"
                @pagechanged="onPageChange"
            />
        </div>
    </div>
    <view-groups-table/>
    <update-groups-form
        v-if="showModal"
        :refresh="refresher"
        :groupData="groupData"
        @close-modal="showModal = false"/>
    </template>
    <script>
    import UpdateGroupsForm from "@/components/Modals/updates/UpdateGroupsForm.vue"
     import Pagination from '@/components/comms/Pagination.vue';
     import { ref, h } from "vue";
     import moment from "moment";
     import {ElMessage, ElMessageBox} from "element-plus";
     import { removeGroup } from "@/api/groups";
     const filter_params = ref({});
     const date = ref('')
     const showModal = ref(false)
     const groupsMessage = ref('')
     const groupData = ref({});
     
    export default {
      name: 'SentBox',
      props: {
        groups: {
        type: Object,
        required: true,
        },
        total_pages: {
        required: true,
        },
        per_page: {
        required: true,
        },
        current_page: {
        required: true,
        },
        refresh: {
        type: Function,
        required: true,
        }
     },
      components: {
        Pagination,
        UpdateGroupsForm
      
      },
     
     
    
      setup(props){
       
        const onPageChange = (page) => {
          // paginate.current_page = page
          props.refresh(filter_params, page)
        };

        const getInitials = (name) => {
            if (name) {
                const nameSplit = name?.split(" ");
                const firstInitials = nameSplit[0]?.charAt(0);
                const secondInitials = nameSplit[1]?.charAt(0);

                return (
                    (firstInitials ? firstInitials : "") +
                    (secondInitials ? secondInitials : "")
                );
                }
                return "";
                };
        const refresher = ()=>{
            props.refresh();
        }

        const dateTime = (value) => {
            return moment(value).format("Do MMM YYYY HH:mm:ss");
        };
        const _filterCallBack = (filter_data) => {
            filter_params.value = filter_data;
            props.refresh(filter_params, 0);
            };
        const updateGroup = (item)=>{
            groupData.value = item
            showModal.value = true

        }

        const deleteGroup = async (id) => {
            ElMessageBox.confirm(
                h(
                    "div",
                    { style: "color: black, font-size: 14px" },
                    "The group and all the members in the group  will be deleted. Continue?"
                ),

                "Delete Group",
                {
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                  type: "warning",
                  center: true,
                }
            ).then(async () => {
              try {
                // isLoading.value = true;
                const { message, success } = await removeGroup(id);

                if (success) {
                  props.refresh();
                  ElMessage({
                    type: "success",
                    message: message,
                  });
                } else {
                  ElMessage({
                    type: "error",
                    message: message,
                  });
                }
              } catch (error) {
                ElMessage({
                  type: "error",
                  message: error.message,
                });
              } finally {
                // isLoading.value = false;
              }
            });
          };
          const viewgroups = (message) => {
            groupsMessage.value = message;
            showModal.value = true;
          };
            
            return {
                _filterCallBack,
                filter_params,
                onPageChange,
                getInitials,
                dateTime,
                date,
                viewgroups,
                groupsMessage,
                showModal,
                deleteGroup,
                groupData,
                updateGroup,
                refresher


            }
    },
     
    }
    </script>
    <style scoped>
    .pag{
      align-content: center;
    }
    /* @media (min-width: 768px) {
      .pag{
        margin-left: 35%;
      }
    } */
    .truncate-text {
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    max-width: 150px; 
    display: inline-block; 
  }
    </style>