import axios from "axios";


export const fetchDashboard = async() => {
    let response = await axios
        .get("/dashboard")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};



