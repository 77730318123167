<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span :style="{ background: $themeColor() }" class="mask  opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img v-if="admin != 1"
              :src="path == 'https://jobs.swifthub.co.ke/api/v1/images?path=null'?logo:path"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
            <img v-else
              :src="logo"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ profileForm.business_name }}</h5>
          </div>
        </div>
        <div  v-if="admin != 1"
          class="text-end mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"

        >
        <span class="text-dark">Set Your Theme Color &nbsp;</span>
        <el-color-picker v-model="color" @change="handleColorChange" />

        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="mt-3 row justify-content-center">
      <div class="col-12 col-md-6 col-xl-4" v-if="admin != 1">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0 text-center">Profile Information</h6>
          </div>
          <div class="p-3 card-body">
            <el-form
              ref="profileFormRef"
              :model="profileForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm"
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
              >
              <el-form-item label="Name" prop="name">
                <el-input v-model="profileForm.name" />
              </el-form-item>
              <el-form-item label="Email" prop="email">
                <el-input v-model="profileForm.email" disabled />
              </el-form-item>
              <div class="mb-4">
                <label class="el-form-item__label">Mobile Number</label>
                <vue-tel-input
                    v-model="profileForm.phone"
                    :value="profileForm.phone"
                    @validate="checkMobileNumber"
                    v-bind="phoneBindingProps"
                ></vue-tel-input>
              </div>
              
              <el-form-item label="Business Name" prop="business_name">
                <el-input v-model="profileForm.business_name" />
              </el-form-item>

              <div class="text-center">
                <el-button
                  :style="{ background: $themeColor() }"
                    class="my-4 mb-2 text-white"
                    :loading="loading1"
                    @click.prevent="submitForm(profileFormRef)"
                >Update</el-button >
              </div> 
              </el-form>
          </div>
        </div>
      </div>
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0" v-if="admin != 1" >
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0 text-center">Logo Settings</h6>
          </div>
          <div class="p-3 card-body mt-5">
            <el-form
          ref="logoFormRef"
          :model="logoForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        
        <el-upload
        class="upload-demo"
        drag
        accept=".jpeg,.jpg,.png,.PNG, .JPEG,.JPG,.gif"
        :before-upload="handleBeforeUpload"
        :on-change="addAttachment"
        :auto-upload="false"
        :on-remove="removeFile"
        action=""
        :file-list="fileList"
        >
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
            Drop image here or <em>click to upload</em>
        </div>
        <template #tip>
            <div class="el-upload__tip">
            upload an Image
            </div>
        </template>
        </el-upload>
        <div class="d-flex justify-content-between">
          <div class="text-center mt-5">
            <el-button
              :style="{ background: $themeColor() }"
              class="my-4 mb-2 text-white"
              :loading="loading2"
              @click.prevent="submitLogoForm(logoFormRef)"
          >Update Logo</el-button >
        </div> 
        <div class="text-center mt-5">
            <el-button
             :style="{ background: $themeColor() }"
              class="my-4 mb-2 text-white"
              :loading="loading3"
              @click.prevent="removeLogo()"
          >Remove Logo</el-button >
        </div> 
        </div>
        </el-form>
          </div>
        </div>
      </div>
     
      <div class="mt-4 col-12 col-xl-4 mt-xl-0">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0 text-center">Password Settings</h6>
          </div>
          <div class="p-3 card-body mt-5 ">
            <el-form
              ref="passwordFormRef"
              :model="passwordForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm"
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
              >
              <el-form-item label="Current Password" prop="current_password">
                <el-input
                  v-model="passwordForm.current_password"
                  type="password"
                  :show-password="true"
                />
              </el-form-item> 
              <el-form-item label="New Password" prop="new_password">
                <el-input
                  v-model="passwordForm.new_password"
                  type="password"
                  :show-password="true"
                />
              </el-form-item> 
              <el-form-item label="Confirm Password" prop="confirm_password">
                <el-input
                  v-model="passwordForm.confirm_password"
                  type="password"
                  :show-password="true"
                />
              </el-form-item> 

              <div class="text-center mt-6">
                <el-button
                 :style="{ background: $themeColor() }"
                  class="my-4 mb-2 text-white"
                  :loading="loading4"
                  @click.prevent="submitPasswordForm(passwordFormRef)"
              >Change Password</el-button >
              </div> 
              </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import {  reactive, ref, watch, onBeforeMount } from "vue";
import {fetchUserInfo} from "@/api/profile"
import { ElNotification } from "element-plus";
import {updateUserInfo} from "@/api/profile";
import {updateLogo} from "@/api/profile";
import {deleteLogo} from "@/api/profile";
import {changePassword} from "@/api/profile";
import { useRouter } from 'vue-router';
import logo from "@/assets/img/africodeit.jpeg";
import { useStore } from "vuex";
import {setColor} from "@/api/profile";



const path = ref('');
export default {
  name: "ProfileOverview",
  components: {
    //
  },
  data(){
    const admin = localStorage.getItem('isAdmin')
    return{
      logo, admin
    }
  },
  
  setup(){
    const loading1 = ref(false)
    const loading2 = ref(false)
    const loading3 = ref(false)
    const loading4 = ref(false)
    const router = useRouter();
    const store = useStore();
    const color = ref(store.state.theme_color);
    const userInfo = ref({});
    const fetchData = async () => {
      store.commit('isLoading', true)
        try {

          const response = await fetchUserInfo();
          userInfo.value = response.data;
          console.log('response user data is',userInfo.value.name)
        } catch (error) {
          console.log(error);
        } finally {
          store.commit('isLoading', false)
        }
      };
 
  const profileFormRef = ref(null);
  const passwordFormRef = ref(null);
  const profileForm = reactive({
    email: '',
    phone: '',
    logo: '',
    name: '',
    business_name: '',
  });
  const passwordForm = reactive({
    new_password: '',
    current_password: '',
    confirm_password: '',
  });

  watch(
    () => userInfo.value,
    (newValue) => {
      // Update profileForm when userInfo changes
      profileForm.email = newValue.email;
      profileForm.phone = newValue.phone;
      profileForm.logo = newValue.logo;
      profileForm.name = newValue.name;
      profileForm.business_name = newValue.businessName;
      path.value = `https://jobs.swifthub.co.ke/api/v1/images?path=${profileForm.logo}`
      store.commit('setLogo', profileForm.logo )
      localStorage.setItem('logo', profileForm.logo)
     
    },
    { immediate: true } // Trigger the watcher immediately
  );

  onBeforeMount(() => {
    fetchData(); // Fetch data when the component is about to mount
  })

    const rules = reactive({
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
      
      name: [
        {
          required: true,
          message: "Please input name",
          trigger: "blur",
        },
       
      ],
      current_password: [
        {
          required: true,
          message: "Please input current password",
          trigger: ["blur", "change"],
        },

        {
          min: 3,
          max: 255,
          message: "Minimum characters should be 3",
          trigger: "blur",
        },
      ],
      new_password: [
        {
          required: true,
          message: "Please input new password",
          trigger: ["blur", "change"],
        },

        {
          min: 3,
          max: 255,
          message: "Minimum characters should be 3",
          trigger: "blur",
        },
      ],
      confirm_password: [
        {
          required: true,
          message: "Please confirm new password",
          trigger: ["blur", "change"],
        },

        {
          min: 3,
          max: 255,
          message: "Minimum characters should be 3",
          trigger: "blur",
        },
      ],
    });
    const phoneBindingProps = {
        mode: "international",
        styleClasses: "has-default",
        defaultCountry: "KE",
        onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
        inputOptions: {
          showDialCode: false,
          placeholder: "Mobile Number",
          name: "phone",
        },
      };
      const checkMobileNumber = (event) => {
        if (event.valid) {
          profileForm.phone = event.number;
          phoneBindingProps.styleClasses = "has-success";
        } else {
          profileForm.phone = "";
          phoneBindingProps.styleClasses = "has-danger";
        }
      };

      const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          loading1.value = true
          const formData = new FormData();
          formData.append("name", profileForm.name);
          formData.append("email", profileForm.email);
          formData.append("phone", profileForm.phone);
          formData.append("business_name", profileForm.business_name);

          updateUserInfo(formData)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  ElNotification({
                    title: "Success",
                    message: response.data.message,
                    type: "success",
                  });
                }else if (response.data.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.data.error,
                    type: 'error',
                  });
              }  else {
                  Object.values(response.data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),
              )
              .finally(() => {
                loading1.value = false
          });
              } else {
                console.log("error submit!", fields);
              }
            });
          };

    const submitPasswordForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          loading4.value = true
          const formData = new FormData();
          formData.append("old_password", passwordForm.current_password);
          formData.append("new_password", passwordForm.new_password);
          formData.append(
            "new_password_confirmation",
            passwordForm.confirm_password
          );
          changePassword(formData)
            .then((data) => {
              console.log(data.data.message)
              if (data.data.success) {
                ElNotification({
                  title: "Success",
                  message: data.data.message,
                  type: "success",
                });
                localStorage.clear();
                console.log('local storage cleared')
                router.push('/log-in');
              }else if (data.data.error) {
                  ElNotification({
                    title: 'Error',
                    message: data.data.error,
                    type: 'error',
                  });
              }  else {
                Object.values(data.data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              }),
            )
            .finally(() => {
              loading4.value = false
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

      const removeLogo = async () => {
        loading3.value = true
        await deleteLogo()
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  ElNotification({
                    title: "Success",
                    message: response.data.message,
                    type: "success",
                  });
                  fetchData();
                }else if (response.data.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.data.error,
                    type: 'error',
                  });
              }  else {
                  Object.values(response.data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),
              )
              .finally(() => {
                loading3.value = false
          });
          };



      const currentFile = ref(null);
      const fileList = ref([]);

      const handleBeforeUpload = (file) => {
      const fileType = file.type;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Add more image types if needed
      const isValidImageType = validImageTypes.includes(fileType);

      if (!isValidImageType) {
        this.$message.error('Please upload a valid image file (JPEG, PNG, GIF, etc.).');
        return false;
      }

      return true;
    };
    const formData = new FormData();
    const addAttachment = (file) => {
      console.log(file);
      formData.append('file', file.raw);
      fileList.value.push(file);
      currentFile.value = file;
    };

    const removeFile = () => {
      if (currentFile.value) {
        console.log('Removing:', currentFile.value);
        fileList.value = fileList.value.filter((file) => file !== currentFile.value);
        currentFile.value = null;
      }
    };



    const submitLogoForm = async () => {
      console.log(path.value)
      if (currentFile.value) {
        loading2.value = true
        await updateLogo(formData)
          .then((data) => {
            console.log('data', data);
            if (data.success) {
              ElNotification({
                title: 'Success',
                message: data.message,
                type: 'success',
              });
              fetchData();
            } else if (data.error) {
              ElNotification({
                title: 'Error',
                message: data.error,
                type: 'error',
              });
            } else {
              Object.values(data.errors).forEach((val) =>
                ElNotification({
                  title: 'Error',
                  message: val[0],
                  type: 'error',
                })
              );
            }
          })
          .catch((err) =>
            ElNotification({
              title: 'Error',
              message: err.message,
              type: 'error',
            })
          )
          .finally(() => {
            removeFile();
            loading2.value = false
          });
      }else{
        ElNotification({
                title: 'Error',
                message: 'Please upload an image',
                type: 'error',
              });
      }
    };

    const handleColorChange = (color_value)=>{
      console.log('value', color_value);
      if(color_value != null){
        let body = {
          color:color_value
        }
        setColor(body)
        .then((response)=>{
          localStorage.setItem('theme_color', color_value)
          store.commit('setThemeColor', color_value)
          ElNotification({
              title: "Success",
              message: response.data.message,
              type: "success",
            });
        })
      }else{
        color.value = store.state.theme_color
      }
    }

     
    return {
      profileFormRef,
      profileForm,
      rules,
      phoneBindingProps,
      checkMobileNumber,
      fetchData,
      userInfo,
      submitForm,
      submitLogoForm,
      passwordForm,
      passwordFormRef,
      handleBeforeUpload,
      addAttachment,
      removeFile,
      fileList,
      removeLogo,
      submitPasswordForm,
      path,
      loading1,
      loading2,
      loading3,
      loading4,
      color,
      handleColorChange



    }
  },
  

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
