<template>
    <div class="container-fluid py-4">
        <div class="col-md-4 px-3" >
            <h6 v-if="groupName">Group Name</h6>
             <p v-if="groupName">{{ groupName }}</p>

             <h6 v-if="groupDescription">Group Description</h6>
             <p v-if="groupDescription">{{ groupDescription }}</p>
            
        </div>
        <!-- <div class="text-end mb- px-2 topup">
            <el-button
                type="success"
                @click="addGroupMembers"
                >
                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Add Group Members
            </el-button>      
        </div> -->
        <div class="d-flex justify-content-center mb-3 px-2" v-if="groupMembers.data && groupMembers.data.length == 0">
            <el-empty :image-size="200" description="All group members will appear here!" />
        </div>
        <group-members-table
        v-else
        :groupMembers="groupMembers"
        :refresh="fetchData"
        :current_page="current_page"
        :total_pages="total_pages"
        :per_page="per_page"
        />
    </div>
    
   
</template>
<script>
import GroupMembersTable from "@/views/components/tables/GroupMembersTable.vue"
import {useStore} from 'vuex'
import {fetchMembersGroup} from "@/api/groups"
import { useRoute } from 'vue-router';
import { ref, onMounted, reactive } from "vue"

export default {
    components: {
        GroupMembersTable
    },

    setup(){
        const groupName = ref('');
        const groupDescription = ref('')
        const route = useRoute();
        const id = ref(route.params.id);
        const groupMembers = ref([]);
        const per_page = ref(0);
        const current_page = ref(0);
        const total_pages = ref(0);
 
        const showModal = ref(false)

        const addGroupMembers = ()=>{
            showModal.value = true
        }

        const store = useStore()
        const fetchData = async (filter, page) => {
            console.log('fetching')
            // isLoading.value = true;
            let entity_params = {};
            try {
            if (filter == undefined) {
                entity_params = ref({});
            } else {
                entity_params = filter;
            }
            store.commit('isLoading', true)
            const response = await fetchMembersGroup(entity_params, page, id.value);
            console.log('response is',response)
            groupMembers.value = response.data;
            console.log('response is',groupMembers.value)

            const total_records = response.data.total || 0;
            per_page.value = response.data.per_page;
            current_page.value = response.data.current_page;
            
            if (per_page.value > 0 && total_records > 0) {
                total_pages.value = Math.ceil(total_records / per_page.value);
            }
            
            } catch (error) {
            console.log(error);
            } finally {
                groupDetails()
            store.commit('isLoading', false)
           
            }
        };
        const paginate = reactive({
                current_page: current_page.value || 1,
                total_pages: total_pages.value,
                per_page: per_page.value,
            });

        onMounted(()=>{
            fetchData()
            console.log('id is', id.value)
        })
        const groupDetails = ()=>{
            console.log('length is', groupMembers.value.data)
            for (let i = 0; i < groupMembers.value.data.length; i++) {
                const item = groupMembers.value.data[i];
                groupName.value = item.group.name;
                groupDescription.value = item.group.description;
                break;
            }
        }
        return {
            showModal,
            addGroupMembers,
            fetchData,
            groupMembers,
            paginate,
            per_page,
            total_pages,
            current_page,
            id,
            groupName,
            groupDescription

        }
    }
}
</script>
<style scoped>
.topup{
  margin-right:5px;
}
</style>