import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    isLoading: false,
    theme_color:'#116FB7',
    logo:'https://jobs.swifthub.co.ke/api/v1/images?path=null'
  },
  
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    isLoading(state, status){
      state.isLoading = status
    },
    setThemeColor(state, value){
      state.theme_color = value
    },
    setLogo(state, value){
      state.logo = `https://jobs.swifthub.co.ke/api/v1/images?path=${value}`
    },
    initializeSystem(state){
      if (localStorage.getItem('theme_color')){
        if(localStorage.getItem('theme_color') == 'null'){
          state.theme_color = '#116FB7'
        }else{
          state.theme_color = localStorage.getItem('theme_color')
        }
      }else{
        state.theme_color = '#116FB7'
      }

      if (localStorage.getItem('logo')){
        if(localStorage.getItem('logo') == 'null'){
          state.logo = 'https://jobs.swifthub.co.ke/api/v1/images?path=null'
        }else{
          state.logo = `https://jobs.swifthub.co.ke/api/v1/images?path=${localStorage.getItem('logo')}`
        }
      }else{
        state.logo = 'https://jobs.swifthub.co.ke/api/v1/images?path=null'
      }
    }
  },

  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
  },
  getters: {},
});
