import axios from "axios";


export const createUser = async(postBody) => {
    let response = await axios
        .post("/create-user", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const fetchUsers = async(filter, page) => {
    let response = await axios
        .get("/fetch-users?", filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeUser = async(id) => {
    let response = await axios
        .delete(`delete-user/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchUnpagUsers = async() => {
    let response = await axios
        .get("/fetch-unpag-users")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

