<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-comment text-primary me-2" />Message
        </div>
        <hr class="horizontal dark m-0" />
  
        <el-form
          ref="emailFormRef"
          :model="emailForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <div>
                    <h5 class="mb-2">{{  emailForm.subject}}</h5>
                    <p v-html="emailForm.message"></p>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref } from "vue";
  const emailFormRef = ref();

  export default {
    name: "email-form",
  
    props: {
      email: {
        type: Object,
        required: false,
      },
    },
  
    setup(props) {
      const emailForm = reactive({
        message: props?.email.message|| "",
        subject: props?.email.subject|| "",
        
      });
  
      return {
        emailForm ,
        emailFormRef,
        props,
        
      };
    },
  };
  </script>
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }
  
  .card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  min-width: 500px;
  max-width: 500px;
  height: 40vh; 
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

.card-body {
  height: auto;
  max-height: calc(40vh - 80px);
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
  .close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
  input {
    border-radius: 6px;
  }


  </style>
  