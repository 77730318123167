import axios from "axios";
const sms_url = (root, filter, page) => {
    var url = root;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.customer_name)
        url = url + "&customer_name=" + filter.value.customer_name;

    if (filter.value != undefined && filter.value.business_name)
    url = url + "&business_name=" + filter.value.business_name;

    if (filter.value != undefined && filter.value.phone)
    url = url + "&phone=" + filter.value.phone;

    if (filter.value != undefined && filter.value.user)
    url = url + "&sent_by=" + filter.value.user;

    if (filter.value != undefined && filter.value.type)
    url = url + "&type=" + filter.value.type;


    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;

    return url;
};

const email_url = (root, filter, page) => {
    var url = root;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.customer_name)
        url = url + "&customer_name=" + filter.value.customer_name;

    if (filter.value != undefined && filter.value.business_name)
    url = url + "&business_name=" + filter.value.business_name;

    if (filter.value != undefined && filter.value.user)
    url = url + "&sent_by=" + filter.value.user;

    if (filter.value != undefined && filter.value.type)
    url = url + "&type=" + filter.value.type;

    if (filter.value != undefined && filter.value.email)
    url = url + "&email=" + filter.value.email;

    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;

    return url;
};

export const fetchSuccessfulSms = async(filter, page) => {
    let response = await axios
        .get(sms_url("/admin-fetch-success-sms?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchFailedSms = async(filter, page) => {
    let response = await axios
        .get(sms_url("/admin-failed-sms?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchSuccessfulEmails = async(filter, page) => {
    let response = await axios
        .get(email_url("/admin-fetch-success-emails?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchFailedEmails = async(filter, page) => {
    let response = await axios
        .get(email_url("/admin-fetch-failed-emails?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchSuccessfulGroupSms = async(filter, page) => {
    let response = await axios
        .get(sms_url("/admin-fetch-success-group-sms?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchFailedGroupSms = async(filter, page) => {
    let response = await axios
        .get(sms_url("/admin-fetch-failed-group-sms?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchSuccessfulGroupEmails = async(filter, page) => {
    let response = await axios
        .get(email_url("/admin-fetch-success-group-emails?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchFailedGroupEmails = async(filter, page) => {
    let response = await axios
        .get(email_url("/admin-fetch-failed-group-emails?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
