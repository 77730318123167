<template>
    <section class="col-md-6 mx-auto py-4 container-fluid">
      <div class="text-start mx-auto">
        <h5>Send Custom Emails</h5>
        <p> To send custom emails, download the sample excel file, fill it and upload. If name is not provided, it will default to customer</p>
      </div>
      <el-form
          ref="uploadsFormRef"
          :model="uploadsForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >

        <div @click.prevent="downloadSample()" class="d-flex mb-4 align-items-center">
            <soft-button
                :style="{ color: $themeColor() }"
                variant="outline"
                size="sm"
                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
            >
              <i class="fas fa-arrow-down" aria-hidden="true"></i>
            </soft-button>
            <div class="d-flex pointer flex-column">
              <h6 class="mb-1 text-dark text-sm">Download Sample</h6>
            </div>
          </div>
        <el-upload
        class="upload-demo"
        drag
        accept=".xls,.xlsx"
        :before-upload="handleBeforeUpload"
        :on-change="addAttachment"
        :auto-upload="false"
        :on-remove="removeFile"
        action=""
        :file-list="fileList"
        >
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
        </div>
        <template #tip>
            <div class="el-upload__tip">
            upload an excel file
            </div>
        </template>
        </el-upload>
        <label class="el-form-item__label mt-2">Sender Email</label>
        <div class="d-flex align-items-center">
                  <el-select v-model="sender_email"
                    prop="email"
                    label="" class="w-100" 
                    placeholder="" size=""
                    filterable
                    clearable>
                    <el-option
                      v-for="item in senders.data"
                      :key="item.id"
                      :label="item.sender_email"
                      :value="item.sender_email"
                    />
                </el-select>
                <div class="">
                    <i
                      class="fa fa-plus password-icon"
                      @click="goToSenders"
                    ></i>
                  </div>
                </div>
        <div class="text-start">
            <el-button
              :style="{ background: $themeColor() }"
              class="my-4 mb-2 text-white"
              :loading="isLoading"
              @click.prevent="submitForm(uploadsFormRef)"
              >Send Email</el-button >
        </div> 
        </el-form>
    </section>
  </template>
  
  <script >
  import SoftButton from "@/components/SoftButton.vue";
  import { UploadFilled } from '@element-plus/icons-vue'
  import { sampleDownload } from "@/api/email";
  import { ElMessage } from "element-plus";
  import { ref, onMounted } from "vue";
  import {ElNotification} from "element-plus";
  import {upload} from "@/api/email"
  import {fetchUnpagSenderEmail} from "@/api/email"
import { useStore } from "vuex";
import { useRouter } from "vue-router";
  const uploadsFormRef = ref();
  const attachment = ref([]);
  const isLoading = ref(false);
  
  export default {
    name: 'Custom Sms',
    components: {
        UploadFilled,
        SoftButton
    },

    setup(){
      const router = useRouter();
      const goToSenders = ()=>{
        router.push('/sender-email-settings')
      }
      const store = useStore();
      const sender_email = ref('')
    const sender_email_address = localStorage.getItem('sender_email')
    const senders = ref([]);
      const currentFile = ref(null);
      const fileList = ref([]);

      const handleBeforeUpload = (file) => {
      const fileType = file.type;
      const validExcelTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];
      const isValidExcelType = validExcelTypes.includes(fileType);

      if (!isValidExcelType) {
        this.$message.error('Please upload a valid Excel file.');
        return false; 
      }

      return true; 
    }

    const addAttachment = (file) => {
      console.log(file);
      const newFormData = new FormData(); 
      newFormData.append('file', file.raw);
      fileList.value.push(file);
      currentFile.value = file;
      
    };

    const removeFile = () => {
      if (currentFile.value) {
        console.log('Removing:', currentFile.value);
        fileList.value = fileList.value.filter((file) => file !== currentFile.value);
        currentFile.value = null;
      }
    };

    const fetchData = async()=>{
            store.commit('isLoading', true)
            try{
                await 
                fetchUnpagSenderEmail()
                    .then((response) => {
                      senders.value = response.data;
                });
            }catch{err => {console.log(err)}}
            finally{
                store.commit('isLoading', false)
            }
        }

    const submitForm = async () => {
      if (currentFile.value) {
        isLoading.value = true
        const newFormData = new FormData();
        newFormData.append('file', currentFile.value.raw);
        newFormData.append('sender_email', sender_email.value);
        await upload(newFormData)
          .then((data) => {
            console.log('data', data);
            if (data.success) {
              ElNotification({
                title: 'Success',
                message: data.message,
                type: 'success',
              });
            } else if (data.error) {
              ElNotification({
                title: 'Error',
                message: data.error,
                type: 'error',
              });
            } else {
              Object.values(data.errors).forEach((val) =>
                ElNotification({
                  title: 'Error',
                  message: val[0],
                  type: 'error',
                })
              );
            }
          })
          .catch((err) =>
            ElNotification({
              title: 'Error',
              message: err.message,
              type: 'error',
            })
          )
          .finally(() => {
            removeFile();
            isLoading.value = false
          });
      }else{
        ElNotification({
                title: 'Error',
                message: 'Please upload an excel file',
                type: 'error',
              });
      }
    };

      async function downloadSample(){
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your download will start shortly. Please wait...",
        });

        await sampleDownload ()
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "sample-email.xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
           
            })
            .catch((err) => {
              console.log(err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      }
    }
    onMounted(()=>{
      fetchData()
    })

      return {
        downloadSample,
        handleBeforeUpload,
        uploadsFormRef,
        submitForm,
        attachment,
        addAttachment,
        removeFile,
        fileList,
        isLoading,
        sender_email,
        sender_email_address,
        senders,
        goToSenders
      }
    }
  }
  </script>
  <style scoped>
  .el-icon--upload{
    height: 10rem;

  }
  .pointer {
  cursor: pointer;
}
.password-icon {
  cursor: pointer;
  margin-left: 5px;
}
  </style>