<template>
    <div class="py-4 container-fluid" v-if="showUpdate == false">
        <div class="text-end mb-3 px-2 topup">
            <el-button
                :style="{ background: $themeColor() }"
                class="text-white"
                @click="goToPermissions"
                >
                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Add Role
            </el-button>      
        </div>
        <div class="d-flex justify-content-center mb-3 px-2" v-if="roles && roles.length == 0">
            <el-empty :image-size="200" description="All Roles will appear here!" />
        </div>
        <div class="row" v-else>
            <div class="col-md-4 mt-3" v-for="(item, key) in roles" :key="key">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <h6 class="card-subtitle">Role</h6>
                            <div class=" align-items-center">
                                <el-button
                                    class="mb-2 ms-2"
                                    type="success"
                                    @click="goToUpdate(item.id)"
                                    >
                                     Update
                                </el-button> 
                                <el-button
                                    class="mb-2 "
                                    type="danger"
                                    @click="del(item.id)"
                                    >
                                     Delete
                                </el-button>      
                            </div>
                        </div>
                        <p>{{item.name}}</p>
                        <h6 class="card-subtitle mb-2 text-muted">Permissions</h6>
                        <ul class="list-unstyled" v-for="(perm, index) in item.permissions"  :key="index">
                            <li><i class="fa fa-check text-success" aria-hidden="true"></i> &nbsp;{{ perm.description }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <update-permissions
    v-if="showUpdate == true"
    :permissions="permissions"
    @permission-updated="permissionUpdated"/>
</template>
<script>
import UpdatePermissions from '@/views/systemUsers/UpdatePermissions.vue'
import { useRouter } from 'vue-router';
import { onMounted, ref, reactive, h } from 'vue';
import { useStore } from 'vuex';
import {fetchRole} from "@/api/permissions"
import {fetchRolePermission, removeRole} from "@/api/permissions"
import {ElMessage, ElMessageBox} from "element-plus";

export default {
    components: {
        UpdatePermissions
    },

    setup(){
      
        const permissions = reactive({
            singlesms:false,
            bulksms:false,
            customsms:false,
            groupsms:false,
            singleemail:false,
            bulkemails:false,
            customemails:false,
            groupemail:false,
            emailreport:false,
            smsreport:false,
            systemusers:false,
            smsgroup:false,
            emailgroup:false,
            groupemailreport:false,
            groupsmsreport:false,
            roleName:'',
            id:0
        })
        const showUpdate = ref(false);
        const store = useStore();
        const roles = ref([])
        const router = useRouter()
        const goToPermissions = ()=>{
            router.push('/permissions')
        }

        const fetchData = async()=>{
            store.commit('isLoading', true)
            try{
                await 
                    fetchRole()
                    .then((response) => {
                    roles.value = response.data;
                });
            }catch{err => {console.log(err)}}
            finally{
                store.commit('isLoading', false)
            }
        }

        onMounted(()=>{
            fetchData()
           
        })

        const goToUpdate = async(id)=>{
            const permList = ref([])
            store.commit('isLoading', true)
            try{
                await 
                    fetchRolePermission(id)
                    .then((response) => {
                   
                    response.data.permissions.forEach((item) => {
                        permList.value.push(item.name)
                    });
                    permissions.singlesms = permList.value.includes('send.singlesms')?true:false
                    permissions.singleemail = permList.value.includes('send.singleemail')?true:false
                    permissions.bulkemails = permList.value.includes('send.bulkemail')?true:false
                    permissions.bulksms = permList.value.includes('send.bulksms')?true:false
                    permissions.customsms = permList.value.includes('send.customsms')?true:false
                    permissions.customemails = permList.value.includes('send.customemail')?true:false
                    permissions.groupsms = permList.value.includes('send.groupsms')?true:false
                    permissions.groupemail = permList.value.includes('send.groupemail')?true:false
                    permissions.emailreport = permList.value.includes('manage.emailreport')?true:false
                    permissions.smsreport = permList.value.includes('manage.smsreport')?true:false
                    permissions.systemusers = permList.value.includes('manage.systemusers')?true:false
                    permissions.groupsmsreport = permList.value.includes('manage.groupsmsreport')?true:false
                    permissions.groupemailreport = permList.value.includes('manage.groupemailreport')?true:false
                    permissions.emailgroup = permList.value.includes('manage.emailgroup')?true:false
                    permissions.smsgroup = permList.value.includes('manage.smsgroup')?true:false
                    permissions.roleName = response.data.name
                    permissions.id = response.data.id
                   
                });
            }catch{err => {console.log(err)}}
            finally{
                store.commit('isLoading', false)
                showUpdate.value = true
            }
            
        }
        const permissionUpdated = ()=>{
            showUpdate.value = false
            fetchData()
        }

        const del = async (id) => {
            ElMessageBox.confirm(
                h(
                    "div",
                    { style: "color: red, font-size: 14px" },
                    "Deleting a role will also delete the associated users. Continue?"
                ),

                "Delete Role",
                {
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                  type: "warning",
                  center: true,
                }
            ).then(async () => {
              try {
                // isLoading.value = true;
                const { message, success } = await removeRole(id);

                if (success) {
                    fetchData()
                  ElMessage({
                    type: "success",
                    message: message,
                  });
                } else {
                  ElMessage({
                    type: "error",
                    message: message,
                  });
                }
              } catch (error) {
                ElMessage({
                  type: "error",
                  message: error.message,
                });
              } finally {
                // isLoading.value = false;
              }
            });
          };
        
       

        return {
            goToPermissions,
            fetchData,
            roles,
            goToUpdate,
            permissions,
            showUpdate,
            permissionUpdated,
            del


        }
    }
}

</script>
<style scoped>
.topup{
  margin-right:5px;
}
.row {
    display: flex;
    flex-wrap: wrap;
}

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card-body {
    flex: 1;
}
</style>