import axios from "axios";
const email_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=log:like;device:like;slug:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.customer_name)
        url = url + "&customer_name=" + filter.value.customer_name;

    if (filter.value != undefined && filter.value.user)
    url = url + "&sent_by=" + filter.value.user;

    if (filter.value != undefined && filter.value.type)
    url = url + "&type=" + filter.value.type;



    if (filter.value != undefined && filter.value.email)
    url = url + "&email=" + filter.value.email;

    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;


    return url;
};
export const fetchSuccessfulEmails = async(filter, page) => {
    let response = await axios
        .get(email_url("/fetch-success-emails?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const singleEmail = async(postBody) => {
    let response = await axios
        .post("/single-email", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const removeMessage = async(id) => {
    let response = await axios
        .delete(`delete-email/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const bulkEmail = async(postBody) => {
    let response = await axios
        .post("/bulk-email", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const sampleDownload = async() => {
    let response = await axios
        .get('sample-email', {
            responseType: "blob",
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const upload = async(body) => {
    let response = await axios
        .post("email-upload", body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchFailedEmails = async(filter, page) => {
    let response = await axios
        .get(email_url("/fetch-failed-emails?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchSenderEmail = async() => {
    let response = await axios
        .get('/fetch-sender-email')
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchUnpagSenderEmail = async() => {
    let response = await axios
        .get('/fetch-unpag-sender-email')
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const storeSenderEmail = async(body) => {
    let response = await axios
        .post('/store-sender-email', body)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const updateSenderEmail = async(body, id) => {
    let response = await axios
        .post(`/update-sender-email/${id}`, body)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const deleteSenderEmail = async(id) => {
    let response = await axios
        .delete(`/delete-sender-email/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

