<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-user-o text-primary me-2" aria-hidden="true"></i>Add User</div>
      
        <el-form
            ref="userFormRef"
            :model="userForm"
            :rules="rules"
            label-width="150px"
            size=""
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="row">
                <div class="col-md-12 mb-2">
                    <el-form-item label="Name" prop="name">
                        <el-input v-model="userForm.name" />
                    </el-form-item>
                </div>
                <div class="col-md-12 mb-2">
                    <el-form-item label="Email" prop="email">
                        <el-input v-model="userForm.email" />
                    </el-form-item>
                </div>

                <div class="col-md-12 mb-2">
                    <label class="el-form-item__label">Mobile Number</label>
                    <vue-tel-input
                        v-model="userForm.phone"
                        :value="userForm.phone"
                        @validate="checkMobileNumber"
                        v-bind="phoneBindingProps"
                    ></vue-tel-input>
                </div>

                <div class="col-md-12 mb-3">
                    <label class="el-form-item__label">Role</label>
                    <el-select v-model="userForm.role" label="" class="w-100" placeholder="" size=""  filterable>
                        <el-option
                        v-for="item in roles"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-md-12 mb-2 d-flex align-items-center">
                  <div class="w-100">
                    <el-form-item label="Password" prop="password">
                    <el-input
                      v-model="userForm.password"
                      type="password"
                      :show-password="true"
                    />
                  </el-form-item>
                  </div>
                  <div class="">
                    <i
                      class="fa fa-key password-icon"
                      @click="generateRandomPassword"
                    ></i>
                  </div>
                </div>
            </div>
          </div>
          <div class="card--footer">
            <el-button
                type="danger"
                :loading="isLoading"
                @click="$emit('close-modal')"
            >Cancel</el-button
            >
            <el-button
                type="success"
                :loading="isLoading"
                @click="submitForm(userFormRef)"
            >Create</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref, onMounted } from "vue";
  import {fetchRole} from "@/api/permissions"
  import { useStore } from 'vuex';
  import { ElNotification } from "element-plus";
  import {createUser} from "@/api/users"
  const userFormRef = ref();

  export default {
    name: "email-form",
  
    props: {
      refresh: {
        type: Object,
        required: false,
      },
    },
  
    setup(props, {emit}) {
        const isLoading = ref(false)
        const store = useStore();
        const roles = ref([])
        const userForm = reactive({
            name:'',
            email:'',
            password:'',
            phone:'',
            role:''
            
        });
        const rules = reactive({
            email: [
                {
                required: true,
                message: "Please input email address",
                trigger: "blur",
                },
                {
                type: "email",
                message: "Please input correct email address",
                trigger: ["blur", "change"],
                },
            ],
            password: [
                {
                required: true,
                message: "Please input password",
                trigger: ["blur", "change"],
                },
            ],
            name: [
                {
                required: true,
                message: "Please input name",
                trigger: ["blur", "change"],
                },
            ],
            role: [
                {
                required: true,
                message: "Please input your password",
                trigger: ["blur", "change"],
                },
            ],
        });
        const phoneBindingProps = {
            mode: "international",
            styleClasses: "has-default",
            defaultCountry: "KE",
            onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
            inputOptions: {
            showDialCode: false,
            placeholder: "Mobile Number",
            name: "phone",
            },
        };
        const checkMobileNumber = (event) => {
            if (event.valid) {
            userForm.phone = event.number;
            phoneBindingProps.styleClasses = "has-success";
            } else {
            userForm.phone = "";
            phoneBindingProps.styleClasses = "has-danger";
            }
        };
        const fetchData = async()=>{
            store.commit('isLoading', true)
            try{
                await 
                    fetchRole()
                    .then((response) => {
                    roles.value = response.data;
                    console.log('roles', roles.value)
                });
            }catch{err => {console.log(err)}}
            finally{
                store.commit('isLoading', false)
            }
        } 
        onMounted(()=>{
            fetchData()
        })
        const generateRandomPassword = ()=>{
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let password = '';
          for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters.charAt(randomIndex);
          }
          userForm.password = password
        }
        const submitForm = async (formEl) => {
            // const phone = userForm.phone;
            // if (phone === '') {
            //     ElNotification({
            //     title: "Error",
            //     message: "Please enter phone number",
            //     type: "error",
            //     })
            //     return;
            // } else if(userForm.phone.length < 12){
            //     ElNotification({
            //     title: "Error",
            //     message: "Please enter correct phone number",
            //     type: "error",
            //     })
            //     return;
            // }
            if (!formEl) return;
            await formEl.validate((valid, fields) => {
                if (valid) {
                isLoading.value = true
                const formData = new FormData();
                formData.append("name", userForm.name);
                formData.append("phone", userForm.phone);
                formData.append("email", userForm.email);
                formData.append("password", userForm.password);
                formData.append("role", userForm.role);

                createUser(formData)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                        ElNotification({
                            title: "Success",
                            message: response.data.message,
                            type: "success",
                        });
                        props.refresh()
                        emit('close-modal')
                        }else if (response.data.error) {
                        ElNotification({
                            title: 'Error',
                            message: response.data.error,
                            type: 'error',
                        });
                    }  else {
                        Object.values(response.data.errors).forEach((val) =>
                            ElNotification({
                                title: "Error",
                                message: val[0],
                                type: "error",
                            })
                        );
                        }
                    })
                    .catch((err) =>
                        console.log(err),
                    )
                    .finally(() => {
                        isLoading.value = false
                });
              } else {
                console.log("error submit!", fields);
              }
            });
        };
        
  
      return {
        userForm ,
        userFormRef,
        props,
        phoneBindingProps,
        rules,
        checkMobileNumber,
        roles,
        submitForm,
        isLoading,
        generateRandomPassword

        
      };
    },

  };
  </script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 500px;
  height: 76vh; 
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}
.card-body {
  height: auto;
  max-height: calc(72vh - 80px);
  overflow-x: hidden;
  overflow-y: auto;
}


.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
.password-icon {
  cursor: pointer;
  margin-left: 5px;
}
</style>
  











  