<template>
  <sidenav
    :custom_class="this.$store.state.mcolor"
    class="bg-white fixed-start"
    v-if="this.$store.state.showSidenav"
  />

  <div
       v-if="store.state.isLoading == true"
      class="overlay1234app d-flex justify-content-center align-items-center"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>


  <main
    
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
  >
 
    <!-- nav -->
    <navbar
      :class="[navClasses]"
      :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar"
    />
    <router-view />
    <app-footer v-show="this.$store.state.showFooter" />
   
  
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
import { useStore } from "vuex";
import { onBeforeMount, onMounted } from 'vue';
import {getColor} from "@/api/profile";
export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  // beforeMount() {
  //   this.$store.state.isTransparent = "bg-white";
  // },
  setup(){
    const store = useStore();

    const fetchData = async()=>{
      await getColor()
      .then((response)=>{
        store.commit('setThemeColor', response.data.data.theme_color)
        localStorage.setItem("theme_color",response.data.data.theme_color );

        store.commit('setLogo', response.data.data.logo)
        localStorage.setItem("logo",response.data.data.logo);
        
      })
    }

    onBeforeMount( ()=> {
      fetchData()
      store.commit('initializeSystem')
    });

   onMounted(()=>{
    //
   })

    return{
      store,
      fetchData
    }
  }
};
</script>

<style>

.overlay1234app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* You can adjust the background color and opacity */
  z-index: 9999; /* Make sure this is higher than other elements on the page */
}
</style>