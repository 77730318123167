<template>
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
    id="sidenav-main"
  >
    <div class="text-center mb-3 mt-3" style="width:10rem; cursor: pointer;" @click="goToDashboard" >
      <img :src="this.$store.state.logo == 'https://jobs.swifthub.co.ke/api/v1/images?path=null'?logo:this.$store.state.logo" class="logo-image ps-2" alt="main_logo" />
    </div>
    <hr class="mt-0 horizontal dark" />

    <admin-sidenav-list :cardBg="customClass" v-if="admin==1" />

    <sidenav-list :cardBg="customClass"  v-else/>

    
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import AdminSidenavList from "./AdminSidenavList.vue";
import logo from "@/assets/img/africodeit.jpeg";
import { mapState } from "vuex";



export default {
  name: "index",
  components: {
    SidenavList, AdminSidenavList
  },
  data() {
    const admin = localStorage.getItem('isAdmin')
    return {
      logo, admin
    };
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  methods: {
    goToDashboard(){
      this.$router.push('/dashboard')
    },
  }
};
</script>
<style scoped>
img{
  width:8rem;
  
 
}
.custom{
  height: 150%;
}
.logo-image {
    width: 100%; /* Set width to fill the container */
    height: auto; /* Allow height to adjust proportionally based on the image's aspect ratio */
    max-width: 100%; /* Ensure the image does not exceed the container's width */
    max-height: 100px; /* Set maximum height */
    /* You can adjust the value of max-height as needed */
}


</style>
