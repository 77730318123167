<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-commenting-o text-primary me-2" aria-hidden="true"></i>Buy Sms</div>
        <hr class="horizontal dark m-0" />
        <div class="text-center">
          <p>To top up sms balance, enter your phone number and amount. You will receive an stk push in your phone.</p>
        </div>
        <el-form
            ref="stkPushFormRef"
            :model="stkPushForm"
            :rules="rules"
            label-width="150px"
            size=""
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <label class="el-form-item__label">Mobile Number</label>
                <vue-tel-input
                    v-model="stkPushForm.phone"
                    :value="stkPushForm.phone"
                    @validate="checkMobileNumber"
                    v-bind="phoneBindingProps"
                ></vue-tel-input>
              </div>
              <div class="col-md-12 mb-3">
                <el-form-item label="Amount" prop="amount">
                  <el-input v-model="stkPushForm.amount" />
                </el-form-item>
              </div>
              <!-- <div class="col-md-12 mb-3">
                <el-form-item label="Payment Code" prop="payment_code">
                  <el-input
                      v-model="stkPushForm.payment_code"
                      disabled
                  />
                </el-form-item>
              </div> -->
            </div>
          </div>
          <div class="card--footer">
            <button
                class="btn mb-0 btn-danger btn-xs null null"
                @click="$emit('close-modal')"
            >
              Cancel
            </button>
            <el-button
                type="success"
                :loading="isLoading"
                @click="submitForm(stkPushFormRef)"
            >Request</el-button
            >
          </div>
        </el-form>
      </div>
      <!-- <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div> -->
    </div>
  </template>
  
  <script>
  import { reactive, ref } from "vue";
  import {buySms} from "@/api/payments";
  import { ElNotification } from "element-plus";
  
  const value2 = ref('')
  const stkPushFormRef = ref();
  const isLoading = ref(false);
  const propertyManager = '';
  
  export default {
    name: "buy-sms-form",
  
    props: {
      payment_code: {
        type: String,
        required: false,
      },
    },
  
    setup(props, { emit }) {
      const stkPushForm = reactive({
        id:  0,
        payment_code: props?.payment_code || 0,
        amount:  "",
        phone: propertyManager || "",
      });
  
      const phoneBindingProps = {
        mode: "international",
        styleClasses: "has-default",
        defaultCountry: "KE",
        onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
        inputOptions: {
          showDialCode: false,
          placeholder: "Mobile Number",
          name: "phone",
        },
      };
  
      const rules = reactive({
  
        payment_code: [
          {
            required: true,
            message: "Please input payment code",
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            message: "Please input phone number",
            trigger: ["blur", "change"],
          },
        ],
        amount: [
          {
            required: true,
            message: "Please input amount",
            trigger: ["blur", "change"],
          },
        ]
      });
  
      const checkMobileNumber = (event) => {
        if (event.valid) {
          stkPushForm.phone = event.number;
          phoneBindingProps.styleClasses = "has-success";
        } else {
          stkPushForm.phone = "";
          phoneBindingProps.styleClasses = "has-danger";
        }
      };
      const submitForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate((valid, fields) => {
          if (valid) {
            isLoading.value = true;
            const formData = new FormData();
            formData.append("amount", stkPushForm.amount);
            formData.append("payment_code", stkPushForm.payment_code);
            formData.append("phone", stkPushForm.phone);
            buySms(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                        ElNotification({
                          title: "Error",
                          message: val[0],
                          type: "error",
                        })
                    );
                  }
                })
                .catch((err) =>
                    console.log(err),
  
                )
                .finally(() => {
                  isLoading.value = false;
                });
          } else {
            console.log("error submit!", fields);
          }
        });
      };
  
      return {
        stkPushForm,
        submitForm,
        rules,
        stkPushFormRef,
        isLoading,
        props,
        phoneBindingProps,
        checkMobileNumber,
        value2
      };
    },
  };
  </script>
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }
  
  .card {
    margin-top: 5%;
    outline: 0;
    border-radius: 5px;
    max-width: 520px;
    max-height: 440px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  }
  .card-body {
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .card-header {
    border-bottom: 1px solid #e9ecef;
    padding: 1.2rem !important;
  }
  .card--footer {
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem 1.2rem 1.2rem !important;
  }
  .close {
    margin: 5% 0 0 10px;
    cursor: pointer;
    background-color: #f4443e;
    color: #ffffff;
    border: none;
    height: 30px;
    width: 30px;
    opacity: .75;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s ease;
  }
  
  .close i {
    font-size: 15px;
  }
  input {
    border-radius: 6px;
  }
  </style>
  