<template>
    <div class="container-fluid py-4">
        <div class="">
        </div>
        <div class="text-end mb- px-2 topup d-flex justify-content-end">
            <el-button
               :style="{ background: $themeColor() }"
                class="text-white"
                @click="addGroupMembers"
                >
                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Add Members
            </el-button>  
            <el-button
                :style="{ background: $themeColor() }"
                class="text-white"
                @click="importMembers"
                >
                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Import Members
            </el-button>        
        </div>
        <div class="d-flex justify-content-center mb-3 px-2" v-if="groupMembers.data && groupMembers.data.length == 0">
            <el-empty :image-size="200" description="All group members will appear here!" />
        </div>
        <group-members-table
        v-else
        :groupMembers="groupMembers"
        :refresh="fetchData"
        :current_page="current_page"
        :total_pages="total_pages"
        :per_page="per_page"
        />
    </div>
    
    <group-members-form
    v-if="showModal"
    :refresh="fetchData"
    @close-modal="showModal = false"/>

    <sms-members-import
    v-if="showImport"
    :refresh="fetchData"
    @close-modal="showImport = false"/>
</template>
<script>
import SmsMembersImport from "@/components/Modals/SmsMembersImport.vue"
import GroupMembersForm from "@/components/Modals/group-sms/GroupMembersForm.vue"
import GroupMembersTable from "@/views/components/tables/group-sms/GroupMembersTable.vue"
import {useStore} from 'vuex'
import {fetchGroupMembers} from "@/api/groups/sms"
import { ref, onMounted, reactive } from "vue"

export default {
    components: {
        GroupMembersForm,
        GroupMembersTable,
        SmsMembersImport
    },

    setup(){
        const showImport = ref(false)
        const groupMembers = ref([]);
        const per_page = ref(0);
        const current_page = ref(0);
        const total_pages = ref(0);
 
        const showModal = ref(false)

        const addGroupMembers = ()=>{
            showModal.value = true
        }

        const store = useStore()
        const fetchData = async (filter, page) => {
            console.log('fetching')
            // isLoading.value = true;
            let entity_params = {};
            try {
            if (filter == undefined) {
                entity_params = ref({});
            } else {
                entity_params = filter;
            }
            store.commit('isLoading', true)
            const response = await fetchGroupMembers(entity_params, page);
            console.log('response is',response)
            groupMembers.value = response.data;
            console.log('response is',groupMembers.value)

            const total_records = response.data.total || 0;
            per_page.value = response.data.per_page;
            current_page.value = response.data.current_page;
            
            if (per_page.value > 0 && total_records > 0) {
                total_pages.value = Math.ceil(total_records / per_page.value);
            }
            
            
            
            } catch (error) {
            console.log(error);
            } finally {
            store.commit('isLoading', false)
            }
        };
        const paginate = reactive({
                current_page: current_page.value || 1,
                total_pages: total_pages.value,
                per_page: per_page.value,
            });

            const importMembers = ()=>{
                showImport.value = true
            }

        onMounted(()=>{
            fetchData()
        })
        return {
            showModal,
            addGroupMembers,
            fetchData,
            groupMembers,
            paginate,
            per_page,
            total_pages,
            current_page,
            showImport,
            importMembers
        }
    }
}
</script>
<style scoped>
.topup{
  margin-right:5px;
}
</style>