import { createRouter, createWebHistory } from "vue-router";


import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
/**============================================================================================================================= */
import Dashboard from "@/views/Dashboard.vue";
import LogIn from "@/views/LogIn.vue";
import Profile from "@/views/Profile.vue";
import SingleSms from "@/views/BulkSms/SingleSms.vue";
import BulkSms from "@/views/BulkSms/BulkSms.vue";
import CustomSms from "@/views/BulkSms/CustomSms.vue";
import SentBox from "@/views/BulkSms/SentBox.vue";

import SingleEmail from "@/views/bulkEmail/SingleEmail.vue";
import BulkEmail from "@/views/bulkEmail/BulkEmail.vue";
import CustomEmail from "@/views/bulkEmail/CustomEmail.vue";
import SentBoxEmail from "@/views/bulkEmail/SentBoxEmail.vue";
import EmailOutBox from "@/views/bulkEmail/EmailOutBox.vue";
import SmsOutBox from "@/views/BulkSms/SmsOutBox.vue";
import Logs from "@/views/Logs.vue";
import SmsReport from "@/views/reports/SmsReport.vue";
import EmailsReport from "@/views/reports/EmailsReport.vue";
import Roles from "@/views/systemUsers/Roles.vue";
import CreatePermissions from "@/views/systemUsers/CreatePermissions.vue";
import Users from "@/views/systemUsers/Users.vue";
import GroupList from "@/views/emailGroup/GroupList.vue";
import GroupMembers from "@/views/emailGroup/GroupMembers.vue";
import ViewGroup from "@/views/emailGroup/ViewGroup.vue";
import SendEmail from "@/views/emailGroup/SendEmail.vue";
import GroupEmailSentBox from "@/views/emailGroup/SentBox.vue";
import GroupEmailOutBox from "@/views/emailGroup/OutBox.vue";

import GroupListSms from "@/views/smsGroup/GroupList.vue";
import GroupMembersSms from "@/views/smsGroup/GroupMembers.vue";
import ViewGroupSms from "@/views/smsGroup/ViewGroup.vue";
import SendSms from "@/views/smsGroup/SendSms.vue";
import GroupSmsSentBox from "@/views/smsGroup/SentBox.vue";
import GroupSmsOutBox from "@/views/smsGroup/OutBox.vue";

import SmsGroupReport from "@/views/reports/SmsGroupReport.vue";
import EmailGroupReport from "@/views/reports/EmailGroupReport.vue";
import Ticket from "@/views/Ticket.vue";
import SenderEmail from "@/views/SenderEmail.vue";

/**================================================ADMIN IMPORTS============================================================================= */

import AdminDashboard from "@/views/admin/AdminDashboard.vue";
import Clients from "@/views/admin/clients/Clients.vue";
import SuccessfulSms from "@/views/admin/communication/SuccessfulSms.vue";
import SuccessfulEmails from "@/views/admin/communication/SuccessfulEmails.vue";
import FailedSms from "@/views/admin/communication/FailedSms.vue";
import FailedEmails from "@/views/admin/communication/FailedEmails.vue";
import GroupSuccessfulSms from "@/views/admin/communication/GroupSuccessfulSms.vue";
import GroupFailedSms from "@/views/admin/communication/GroupFailedSms.vue";
import GroupSuccessfulEmails from "@/views/admin/communication/GroupSuccessfulEmails.vue";
import GroupFailedEmails from "@/views/admin/communication/GroupFailedEmails.vue";
import AdminTicket from "@/views/admin/ticket/AdminTicket.vue";
import ViewClient from "@/views/admin/clients/ViewClient.vue";


const routes = [
 
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
    
  },

  /**================================================================================================================= */
  {
    path: "/",
    name: "/",
    redirect: "/log-in",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: "/send-single-sms",
    name: "Single Sms",
    component: SingleSms,
    meta: { requiresAuth: true }
  },
  {
    path: "/send-bulk-sms",
    name: "Bulk Sms",
    component: BulkSms,
    meta: { requiresAuth: true }
  },
  {
    path: "/send-custom-sms",
    name: "Custom Sms",
    component: CustomSms,
    meta: { requiresAuth: true }
  },
  {
    path: "/sent-box",
    name: "Sms Sent Box",
    component: SentBox,
    meta: { requiresAuth: true }
  },

  {
    path: "/email-sent-box",
    name: "Emails Sent Box",
    component: SentBoxEmail,
    meta: { requiresAuth: true }
  },
  {
    path: "/send-single-email",
    name: "Single Email",
    component: SingleEmail,
    meta: { requiresAuth: true }
  },
  {
    path: "/send-bulk-email",
    name: "Bulk Email",
    component: BulkEmail,
    meta: { requiresAuth: true }
  },
  {
    path: "/send-custom-email",
    name: "Custom Email",
    component: CustomEmail,
    meta: { requiresAuth: true }
  },
  {
    path: "/logs",
    name: "Logs",
    component: Logs,
    meta: { requiresAuth: true }
  },

  {
    path: "/sms-out-box",
    name: "Sms Out Box",
    component: SmsOutBox,
    meta: { requiresAuth: true }
  },

  {
    path: "/email-out-box",
    name: "Email Out Box",
    component: EmailOutBox,
    meta: { requiresAuth: true }
  },
  {
    path: "/sms-report",
    name: "Sms Report",
    component: SmsReport,
    meta: { requiresAuth: true }
  },
  {
    path: "/emails-report",
    name: "Emails Report",
    component: EmailsReport,
    meta: { requiresAuth: true }
  },

  {
    path: "/roles-and-permissions",
    name: "Roles And Permissions",
    component: Roles,
    meta: { requiresAuth: true }
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: CreatePermissions,
    meta: { requiresAuth: true }
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: { requiresAuth: true }
  },
  {
    path: "/email-group-list",
    name: "Email Group List",
    component: GroupList,
    meta: { requiresAuth: true }
  },
  {
    path: "/email-group-members",
    name: "Email Group Members",
    component: GroupMembers,
    meta: { requiresAuth: true }
  },
  {
    path: "/group/:id",
    name: "View Group",
    component: ViewGroup,
    meta: { requiresAuth: true }
  },
  {
    path: "/send-group-email",
    name: "Send Group Email",
    component: SendEmail,
    meta: { requiresAuth: true }
  },

  {
    path: "/email-group-sent-box",
    name: "Sent Email Messages",
    component: GroupEmailSentBox,
    meta: { requiresAuth: true }
  },

  {
    path: "/email-group-out-box",
    name: "Failed Email Messages",
    component: GroupEmailOutBox,
    meta: { requiresAuth: true }
  },

  {
    path: "/sms-group-list",
    name: "Sms Group List",
    component: GroupListSms,
    meta: { requiresAuth: true }
  },
  {
    path: "/sms-group-members",
    name: "Sms Group Members",
    component: GroupMembersSms,
    meta: { requiresAuth: true }
  },
  {
    path: "/group-sms/:id",
    name: "View Groups",
    component: ViewGroupSms,
    meta: { requiresAuth: true }
  },
  {
    path: "/send-group-sms",
    name: "Send Group Sms",
    component: SendSms,
    meta: { requiresAuth: true }
  },

  {
    path: "/sms-group-sent-box",
    name: "Sent Messages",
    component: GroupSmsSentBox,
    meta: { requiresAuth: true }
  },

  {
    path: "/sms-group-out-box",
    name: "Failed Messages",
    component: GroupSmsOutBox,
    meta: { requiresAuth: true }
  },
  {
    path: "/sms-groups-report",
    name: "Sms Group Report",
    component: SmsGroupReport,
    meta: { requiresAuth: true }
  },
  {
    path: "/email-groups-report",
    name: "Email Group Report",
    component: EmailGroupReport,
    meta: { requiresAuth: true }
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: Ticket,
    meta: { requiresAuth: true }
  },

  {
    path: "/sender-email-settings",
    name: "Sender Emails",
    component:  SenderEmail,
    meta: { requiresAuth: true }
  },
 

  {
    path: "/log-in",
    name: "login",
    component: LogIn,
    
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
   
  },

   /**=======================================ADMIN ROUTES========================================================================== */
   {
    path: "/admin-dashboard",
    name: "Admin Dashboard",
    component: AdminDashboard,
    meta: { requiresAdmin: true, requiresAuth: true}
  },

  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: { requiresAdmin: true, requiresAuth: true  }
  },
  {
    path: "/admin-sent-box",
    name: "SuccessFul Sms",
    component: SuccessfulSms,
    meta: {  requiresAdmin: true, requiresAuth: true }
  },

  {
    path: "/admin-sms-out-box",
    name: "Failed Sms",
    component: FailedSms,
    meta: { requiresAdmin: true, requiresAuth: true }
  },

  {
    path: "/admin-email-sent-box",
    name: "Successful Emails",
    component: SuccessfulEmails,
    meta: { requiresAdmin: true, requiresAuth: true }
  },

  {
    path: "/admin-email-out-box",
    name: "Failed Emails",
    component: FailedEmails,
    meta: { requiresAdmin: true, requiresAuth: true }
  },

  {
    path: "/admin-sms-group-sent-box",
    name: "Group SuccessFul Sms",
    component:GroupSuccessfulSms,
    meta: { requiresAdmin: true, requiresAuth: true }
  },

  {
    path: "/admin-sms-group-out-box",
    name: "Group Failed Sms",
    component: GroupFailedSms,
    meta: { requiresAdmin: true, requiresAuth: true }
  },

  {
    path: "/admin-email-group-sent-box",
    name: "Group SuccessFul Emails",
    component: GroupSuccessfulEmails,
    meta: { requiresAdmin: true, requiresAuth: true }
  },

  {
    path: "/admin-email-group-out-box",
    name: "Group Failed Emails",
    component: GroupFailedEmails,
    meta: { requiresAdmin: true, requiresAuth: true }
  },

  {
    path: "/admin-tickets",
    name: "Admin Tickets",
    component: AdminTicket,
    meta: { requiresAdmin: true, requiresAuth: true }
  },

  {
    path: "/client/:id",
    name: "View Client",
    component: ViewClient,
    meta: { requiresAdmin: true, requiresAuth: true }
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (localStorage.getItem('isAuthenticated') != 1) {
//       next({ name: 'login' });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === '1';
  const isAdmin = localStorage.getItem('isAdmin') === '1'; 

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: 'login' });
    } else if (to.matched.some((record) => record.meta.requiresAdmin) && !isAdmin) {
      next({ name: 'Dashboard' }); 
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;
