<template>
    <el-popover
       class="col-sm-3"
        placement="bottom"
        title="Filter Cases"
        :width="500"
        trigger="click"
        :visible="filter_visible"
    >
      <template #reference>
        <el-button
            :style="{ background: $themeColor() }"
            class="m-2 btn-xs null null ms-auto text-white"
            @click="filter_visible = !filter_visible"
        ><i class="fa fa-filter me-1" aria-hidden="true"></i>&nbsp;Filter
        </el-button>
      </template>
      <el-form
          ref="filter_paramsRef"
          label-width="0px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
          style="margin-bottom: 20px;"
      >
        <div>
          <hr>
        </div>
  
        <div v-if="show_sms_table_filter" class="row align-items-center">
          <div class="col-sm-3 col-md-6" style="margin-top:-2.5%;">
            <label class="form-control-label">Sent By</label>
              <el-select  v-model="filter_params.user"
                  class="w-100" 
                  placeholder="" size=""
                  clearable
                  filterable
                  >
                  <el-option
                  v-for="(item, index) in users.data"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                  />
              </el-select>
          </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Customer Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.customer_name"
                    placeholder="Please input customer name"
                    clearable
                />
              </el-form-item>
            </div>
          <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Phone</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.phone"
                    placeholder="Please input phone"
                    clearable
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Date Range</label>
              <el-form-item prop="date_range">
                <el-date-picker
                    v-model="filter_params.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6 mb-2" style="margin-top:-2.5%;">
              <label class="form-control-label">Type</label>
                <el-select  v-model="filter_params.type"
                    label="" class="w-100" 
                    placeholder="" size=""
                    clearable
                    >
                    <el-option
                    v-for="(item, index) in type"
                    :key="index"
                    :label="item"
                    :value="item"
                    />
                </el-select>
            </div>
        </div>

        <div v-if="show_emails_table_filter" class="row">
          <div class="col-sm-3 col-md-6" style="margin-top:-2.5%;">
            <label class="form-control-label">Sent By</label>
              <el-select  v-model="filter_params.user"
                  class="w-100" 
                  placeholder="" size=""
                  clearable
                  filterable
                  >
                  <el-option
                  v-for="(item, index) in users.data"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                  />
              </el-select>
          </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Customer Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.customer_name"
                    placeholder="Please input customer name"
                    clearable
                />
              </el-form-item>
            </div>
          <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Email</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.email"
                    placeholder="Please input email"
                    clearable
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Date Range</label>
              <el-form-item prop="date_range">
                <el-date-picker
                    v-model="filter_params.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>

            <div class="col-sm-3 col-md-6 mb-2" style="margin-top:-2.5%;">
              <label class="form-control-label">Type</label>
                <el-select  v-model="filter_params.type"
                    label="" class="w-100" 
                    placeholder="" size=""
                    clearable
                    >
                    <el-option
                    v-for="(item, index) in type"
                    :key="index"
                    :label="item"
                    :value="item"
                    />
                </el-select>
            </div>
        </div>

        <div v-if="show_group_emails_table_filter" class="row">
          <div class="col-sm-3 col-md-6" style="margin-top:">
            <label class="form-control-label">Sent By</label>
              <el-select  v-model="filter_params.user"
                  class="w-100" 
                  placeholder="" size=""
                  clearable
                  filterable
                  >
                  <el-option
                  v-for="(item, index) in users.data"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                  />
              </el-select>
          </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Customer Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.customer_name"
                    placeholder="Please input customer name"
                    clearable
                />
              </el-form-item>
            </div>
          <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Email</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.email"
                    placeholder="Please input email"
                    clearable
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Date Range</label>
              <el-form-item prop="date_range">
                <el-date-picker
                    v-model="filter_params.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>

            <div class="col-sm-3 col-md-6 mb-2" style="margin-top:">
              <label class="form-control-label">Group</label>
                <el-select  v-model="filter_params.group"
                    label="" class="w-100" 
                    placeholder="" size=""
                    clearable
                    filterable
                    >
                    <el-option
                    v-for="item in groups"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                    />
                </el-select>
            </div>
        </div>


        <div v-if="show_group_sms_table_filter" class="row">
          <div class="col-sm-3 col-md-6" style="margin-top:">
            <label class="form-control-label">Sent By</label>
              <el-select  v-model="filter_params.user"
                  class="w-100" 
                  placeholder="" size=""
                  clearable
                  filterable
                  >
                  <el-option
                  v-for="(item, index) in users.data"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                  />
              </el-select>
          </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Customer Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.customer_name"
                    placeholder="Please input customer name"
                    clearable
                />
              </el-form-item>
            </div>
          <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Phone</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.phone"
                    placeholder="Please input phone"
                    clearable
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Date Range</label>
              <el-form-item prop="date_range">
                <el-date-picker
                    v-model="filter_params.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>

            <div class="col-sm-3 col-md-6 mb-2" style="margin-top:">
              <label class="form-control-label">Group</label>
                <el-select  v-model="filter_params.groupSms"
                    label="" class="w-100" 
                    placeholder="" size=""
                    clearable
                    filterable
                    >
                    <el-option
                    v-for="item in groupsSms"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                    />
                </el-select>
            </div>
        </div>



        <div v-if="show_logs_table_filter" class="row">
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.name"
                    placeholder="Please input name"
                    clearable
                />
              </el-form-item>
            </div>
          
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Date Range</label>
              <el-form-item prop="date_range">
                <el-date-picker
                    v-model="filter_params.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>
        </div>

        <div v-if="show_admin_tickets_table_filter" class="row">
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Status</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.name"
                    placeholder="Please input status"
                    clearable
                />
              </el-form-item>
            </div>
          
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Business Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.business_name"
                    placeholder="Please input business name"
                    clearable
                />
              </el-form-item>
            </div>
        </div>

        <div v-if="show_clients_table_filter" class="row">
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.name"
                    placeholder="Please input name"
                    clearable
                />
              </el-form-item>
            </div>

            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Email</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.email"
                    placeholder="Please input email"
                    clearable
                />
              </el-form-item>
            </div>

            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Phone</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.phone"
                    placeholder="Please input phone"
                    clearable
                />
              </el-form-item>
            </div>

            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Business Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.business_name"
                    placeholder="Please input business name"
                    clearable
                />
              </el-form-item>
            </div>
        </div>

        <div v-if="show_admin_sms_table_filter" class="row align-items-center">
          <div class="col-sm-3 col-md-6" style="margin-top:-2.5%;">
            <label class="form-control-label">Sent By</label>
              <el-select  v-model="filter_params.user"
                  class="w-100" 
                  placeholder="" size=""
                  clearable
                  filterable
                  >
                  <el-option
                  v-for="(item, index) in users.data"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                  />
              </el-select>
          </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Customer Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.customer_name"
                    placeholder="Please input customer name"
                    clearable
                />
              </el-form-item>
            </div>
          <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Phone</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.phone"
                    placeholder="Please input phone"
                    clearable
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Date Range</label>
              <el-form-item prop="date_range">
                <el-date-picker
                    v-model="filter_params.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6 mb-2" style="margin-top:-2.5%;">
              <label class="form-control-label">Type</label>
                <el-select  v-model="filter_params.type"
                    label="" class="w-100" 
                    placeholder="" size=""
                    clearable
                    >
                    <el-option
                    v-for="(item, index) in type"
                    :key="index"
                    :label="item"
                    :value="item"
                    />
                </el-select>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Business Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.business_name"
                    placeholder="Please input business name"
                    clearable
                />
              </el-form-item>
            </div>
        </div>

        <div v-if="show_admin_emails_table_filter" class="row">
          <div class="col-sm-3 col-md-6" style="margin-top:-2.5%;">
            <label class="form-control-label">Sent By</label>
              <el-select  v-model="filter_params.user"
                  class="w-100" 
                  placeholder="" size=""
                  clearable
                  filterable
                  >
                  <el-option
                  v-for="(item, index) in users.data"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                  />
              </el-select>
          </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Customer Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.customer_name"
                    placeholder="Please input customer name"
                    clearable
                />
              </el-form-item>
            </div>
          <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Email</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.email"
                    placeholder="Please input email"
                    clearable
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Date Range</label>
              <el-form-item prop="date_range">
                <el-date-picker
                    v-model="filter_params.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>

            <div class="col-sm-3 col-md-6 mb-2" style="margin-top:-2.5%;">
              <label class="form-control-label">Type</label>
                <el-select  v-model="filter_params.type"
                    label="" class="w-100" 
                    placeholder="" size=""
                    clearable
                    >
                    <el-option
                    v-for="(item, index) in type"
                    :key="index"
                    :label="item"
                    :value="item"
                    />
                </el-select>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Business Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.business_name"
                    placeholder="Please input business name"
                    clearable
                />
              </el-form-item>
            </div>
        </div>


        <div v-if="show_admin_group_sms_table_filter" class="row">
          <div class="col-sm-3 col-md-6" style="margin-top:">
            <label class="form-control-label">Sent By</label>
              <el-select  v-model="filter_params.user"
                  class="w-100" 
                  placeholder="" size=""
                  clearable
                  filterable
                  >
                  <el-option
                  v-for="(item, index) in users.data"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                  />
              </el-select>
          </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Customer Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.customer_name"
                    placeholder="Please input customer name"
                    clearable
                />
              </el-form-item>
            </div>
          <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Phone</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.phone"
                    placeholder="Please input phone"
                    clearable
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Date Range</label>
              <el-form-item prop="date_range">
                <el-date-picker
                    v-model="filter_params.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>

            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Business Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.business_name"
                    placeholder="Please input business name"
                    clearable
                />
              </el-form-item>
            </div>
        </div>

        <div v-if="show_admin_group_emails_table_filter" class="row">
          <div class="col-sm-3 col-md-6" style="margin-top:">
            <label class="form-control-label">Sent By</label>
              <el-select  v-model="filter_params.user"
                  class="w-100" 
                  placeholder="" size=""
                  clearable
                  filterable
                  >
                  <el-option
                  v-for="(item, index) in users.data"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                  />
              </el-select>
          </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Customer Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.customer_name"
                    placeholder="Please input customer name"
                    clearable
                />
              </el-form-item>
            </div>
          <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Email</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.email"
                    placeholder="Please input email"
                    clearable
                />
              </el-form-item>
            </div>
            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Date Range</label>
              <el-form-item prop="date_range">
                <el-date-picker
                    v-model="filter_params.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>

            <div class="col-sm-3 col-md-6">
              <label for="status" class="form-control-label">Business Name</label>
              <el-form-item  class="class-body">
                <el-input
                    v-model="filter_params.business_name"
                    placeholder="Please input business name"
                    clearable
                />
              </el-form-item>
            </div>
        </div>
  
        <div class="row">
          <div class="col-md-6 col-sm-3">
            <el-button
                class="col-md-6 custom-green-button col-sm-3"
                style="width: 100%;"
                @click="filterList"
            >Filter</el-button>
          </div>
          <div class="col-md-6 col-sm-3">
            <el-button
                type="danger"
                class="col-md-6 col-sm-3"
                style="width: 100%;"
                @click="closeFilter"
            >Cancel</el-button>
          </div>
        </div>
      </el-form>
    </el-popover>
  </template>
  
  <script>
  
  import {reactive, ref} from "vue";
  import {fetchUnpagUsers} from "@/api/users"
  import {fetchUnpagGroup} from "@/api/groups"
  import {fetchUnpagGroupSms} from "@/api/groups/sms"
 
  
  const filter_visible = ref(false);
  const users = ref([]);
  const filter_paramsRef = ref();
  const type = ['Single', 'Bulk', 'Custom']
  const groups = ref([])
  const groupsSms = ref([])
  const shortcuts = [
    {
      text: 'Last week',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        return [start, end]
      },
    },
    {
      text: 'Last month',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        return [start, end]
      },
    },
    {
      text: 'Last 3 months',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        return [start, end]
      },
    },
  ];
  export default {
    name: "FiltersForm",
   
    props: {
      
      show_sms_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_admin_sms_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_emails_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_admin_emails_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_group_emails_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_admin_group_emails_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_group_sms_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_admin_group_sms_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_logs_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_admin_tickets_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      show_clients_table_filter: {
        type: Boolean,
        required: false,
        default: false,
      },
      filter_init: {
        type: Object,
        required: false,
      },
      filterCallBack: {
        type: Function,
        required: true,
      },
     
    },
    setup(props) {
      const filter_params = reactive({
        phone: props.filter_init.phone ?? null,
        email: props.filter_init.email ?? null,
        name: props.filter_init.name ?? null,
        date_range: props.filter_init.date_range ?? null,
        customer_name: props.filter_init.customer_name ?? null,
        type: props.filter_init.type ?? null,
        user: props.filter_init.user ?? null,
        group: props.filter_init.group ?? null,
        groupSms: props.filter_init.groupSms ?? null,
        business_name: props.filter_init.business_name ?? null,
        
      });
      const closeFilter = () => {
        filter_params.phone= "";
        filter_params.email= "";
        filter_params.type= "";
        filter_params.name= "";
        filter_params.user= "";
        filter_params.group= "";
        filter_params.groupSms= "";
        filter_params.date_range = "";
        filter_params.propertyId = "";
        filter_params.customer_name = "";
        filter_params.business_name = "";
        filter_visible.value = false;
      };
      const filterList = () => {
        filter_visible.value = false;
        props.filterCallBack(filter_params);
      };
  
      return {
        filter_paramsRef,
        filter_params,
        shortcuts,
        filter_visible,
        closeFilter,
        filterList,
        type,
        users,
        groups,
        groupsSms
      };
    },
  
    created() {
    this.fetchUsersList();
    this.fetchGroup()
    this.fetchSmsGroup()
   
    },
    methods: {
      async fetchUsersList(){
        try {
          const response = await fetchUnpagUsers();
            users.value = response
        } catch (error) {
          console.log(error);
        }
      },
      async fetchGroup(){
        try{
              await 
                  fetchUnpagGroup()
                  .then((response) => {
                  groups.value = response.data;
                  console.log('groups', groups.value)
              });
          }catch{err => {console.log(err)}}
      },
      async fetchSmsGroup(){
        try{
              await 
                  fetchUnpagGroupSms()
                  .then((response) => {
                  groupsSms.value = response.data;
                  console.log('groups', groups.value)
              });
          }catch{err => {console.log(err)}}
      }
    },
  }
  </script>
  
  
  <style scoped>
  .custom-green-button{
    background-color: #5f924d;
    color: #fff;
  }
  @media (max-width: 768px) {
    .col-sm-3{
        width:70%;
}
  }
 
  </style>