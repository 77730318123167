import axios from "axios";

export const fetchUserInfo = async() => {
    let response = await axios
        .get("fetch-user-info")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateUserInfo = async(postBody) => {
    let response = await axios
        .post("/update-user-info", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const updateLogo = async(body) => {
    let response = await axios
        .post("update-logo", body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const deleteLogo = async() => {
    let response = await axios
        .get("remove-logo")
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};


export const changePassword = async(postBody) => {
    let response = await axios
        .post("/change-password", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};

export const setColor = async(body) => {
    let response = await axios
    .post('/set-color', body)
    .then((response)=>response)
    .catch((e)=>e.response)
    return response;
}

export const getColor = async(body) => {
    let response = await axios
    .get('/get-color', body)
    .then((response)=>response)
    .catch((e)=>e.response)
    return response;
}












