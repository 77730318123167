<template>
    <section class="py-4 container-fluid">
      <div class="text-start ">
        <h5>Send Bulk Sms</h5>
        <p> To send bulk sms, enter the message, enter phone and name separated by a hyphen each in a new line. If name is not provided, it will default to customer</p>
      </div>
      <div class="flex ">
        <div class="">
            <el-form 
              ref="smsFormRef"
              :model="smsForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm "
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true">
            <el-form-item label="Names and Phone Numbers (one per line):" prop="phone">
                  <el-input v-model="smsForm.phone" placeholder="John Doe - 0795822141" type="textarea" rows="21" />
                  <small class="ms-1">Total Contacts: {{ contactCount() }}</small>
              </el-form-item>
          </el-form>
        </div>
        <div class="card z-index-0 col-md-6 mx-auto ">
          <div class="card-body">
              <el-form
              ref="smsFormRef"
              :model="smsForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm "
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
              >
              <div class="mt-4 editor-container ">
                <label class="el-form-item__label">Message</label>
                <div ref="compose_sms_editor"></div>
                <small :class="{ 'text-red': characterCount > 160 } ">Character Count: {{ characterCount }}/160</small>
                <div class="text-start">
                    <el-button
                      :style="{ background: $themeColor() }"
                      class="my-4 mb-2 text-white"
                      :loading="isLoading"
                      @click.prevent="submitForm(smsFormRef)"
                      >Send SMS</el-button >
                </div> 
              </div>
              </el-form>
            </div>
        </div>
      </div>
    </section>
</template>
<script>
import { ref, reactive, onMounted, watch } from "vue";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import {bulkSms} from "@/api/sms"
import { ElNotification } from "element-plus";

const isLoading = ref(false)
export default {
  name: "communication",
  components: {
    //
   
  },
 
    setup(){
    const characterCount = ref(0);
    const compose_sms_editor = ref(null);
    const smsFormRef = ref(null);
    const smsForm =reactive({
      phone: '',
      message:''
    })

    const rules = reactive({
      message: [
        {
          required: true,
          message: "Please input Message",
          trigger: "blur",
        },
        
      ],
      phone: [
        {
          required: true,
          message: "Please input phone",
          trigger: "blur"
        },
      ],
    });
    const contactCount = ()=>{
      return smsForm.phone.split(/\r\n|\r|\n/).filter(line => line.trim() !== '').length;
    };
     
      onMounted(() => {
        
        const quill = new Quill(compose_sms_editor.value, {
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, 4, false] }],
              ["bold", "italic", "underline", "strike", "link"],
              [{ list: "bullet" }, { list: "ordered" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ align: [] }],
              ["image"],
              ["clean"],
              ["page-break"],
              ["template"],
              [{ 'font': [ 'sans-serif', 'serif'] }],
              [{ 'color': [] }],
              ["copy", "cut", "paste"],
              ["preview", "fullscreen"],
              ["table"],
  
            ],
          },
          theme: "snow",
          fonts: {
            'customFont': 'Your Custom Font, sans-serif',
            'Helvetica': 'Helvetica, sans-serif',
            'Arial': 'Arial, sans-serif',
            'Times New Roman': '"Times New Roman", serif',
            'Comic Sans': '"Comic Sans MS", cursive',
            'Tahoma': 'Tahoma, sans-serif',
            'script': 'Script Font, cursive',
            'monospaced': 'Monospace Font, monospace',
            'display': 'Display Font, cursive',
          },
          formats: [
            "bold",
            "underline",
            "strike",
            "header",
            "italic",
            "link",
            "list",
            "indent",
            "align",
            "image",
            "page-break",
            "template",
            "font",
            "link",
            "image",
            "color",
            "preview",
            "fullscreen",
            "table",
          ],
          placeholder: " You can use {name} as a placeholder for name e.g Dear {name}, thank you for using our services",
        });
  
        quill.root.classList.add('ql-font-customFont');
  
        quill.getModule('toolbar').addHandler('color', (value) => {
          if (value === 'reset') {
            quill.format('color', false);
          } else {
            quill.format('color', value);
          }
        });

        quill.on("text-change", () => {
        smsForm.message = quill.root.textContent;
      });
  
      });
    
      watch(smsForm, () => {
        characterCount.value = smsForm.message.length;
    });

    const submitForm = async (formEl) => {
      const message = smsForm.message;
      const phone = smsForm.phone;
      if (message === '' || phone === '') {
        ElNotification({
          title: "Error",
          message: "Please fill up all fields",
          type: "error",
        })
        return;
      } 
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true
          const lines = (smsForm.phone || '').split(/\r?\n/);
          console.log(lines);

          const formData = new FormData();

          lines.forEach((line, index) => {
          formData.append(`contacts[${index}]`, line.trim());
          });
          formData.append("message", smsForm.message);

          bulkSms(formData)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  ElNotification({
                    title: "Success",
                    message: response.data.message,
                    type: "success",
                  });
                  smsForm.phone = ""
                  smsForm.message = ""
                  const quill = compose_sms_editor.value.__quill;
                  quill.setText('');
                  // Reset the editor to its initial state
                  quill.clipboard.dangerouslyPasteHTML('<div data-placeholder="Compose Message"></div>');
                  }else if (response.data.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.data.error,
                    type: 'error',
                  });
              }  else {
                  Object.values(response.data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),
              )
              .finally(() => {
               
                isLoading.value = false
          });
              } else {
                console.log("error submit!", fields);
              }
            });
          };

       

        return{
            smsForm,
            smsFormRef,
            rules,
            compose_sms_editor,
            characterCount,
            submitForm,
            isLoading,
            contactCount
            
        }
    }
};
</script>
<style scoped>
hr{
    color:green;
}
.custom-select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 20px;
}
.tbtn{
    margin-top: -2%;
}
.text-red {
  color: red;
}
.editor-container{
    height:10rem !important;
    max-height:60rem !important;
}
.card{
    height: 32rem;
}
small{
    font-size: 12px;
}

@media (min-width: 768px) {
   
    .flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .editor-container{
    height:12rem !important;
    max-height:60rem !important;
}
.card{
    height: 30rem;
}
}

</style>