<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-ticket text-primary me-2" aria-hidden="true"></i>Raise A Ticket</div>
      
        <el-form
            ref="ticketFormRef"
            :model="ticketForm"
            :rules="rules"
            label-width="150px"
            size=""
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="row">
               
                <div class="col-md-12 mb-2 w-100">
                    <el-form-item label="Describe The Issue" prop="description" class="w-100">
                        <el-input v-model="ticketForm.description" :rows="10" type="textarea" />
                    </el-form-item>
                </div>
            </div>

          <el-upload
            class="upload-demo"
            accept=".jpeg,.jpg,.png,.PNG, .JPEG,.JPG,.gif"
            :before-upload="handleBeforeUpload"
            :on-change="addAttachment"
            :auto-upload="false"
            :on-remove="removeFile"
            action=""
            :file-list="fileList"
            >
            <el-button type="primary">Click to upload</el-button>
            <div class="el-upload__text ms-3">
              upload a screenshot
            </div>
            <template #tip>
                <div class="el-upload__tip">
               
                </div>
            </template>
          </el-upload>


          </div>
          <div class="card--footer">
            <el-button
                type="danger"
                :loading="isLoading"
                @click="$emit('close-modal')"
            >Cancel</el-button
            >
            <el-button
                type="success"
                :loading="isLoading"
                @click="submitForm(ticketFormRef)"
            >Create</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref } from "vue";
  import { ElNotification } from "element-plus";
  import {raiseTicket} from "@/api/ticket"
  const ticketFormRef = ref();


  export default {
    name: "email-form",
  
    props: {
      refresh: {
        type: Object,
        required: false,
      },
    },
    
    setup(props, {emit}) {

      const currentFile = ref(null);
      const fileList = ref([]);

      const handleBeforeUpload = (file) => {
      const fileType = file.type;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']; 
      const isValidImageType = validImageTypes.includes(fileType);

      if (!isValidImageType) {
        this.$message.error('Please upload a valid image file (JPEG, PNG, GIF, etc.).');
        return false;
      }
      return true;
    };
    
    const addAttachment = (file) => {
      console.log(file);
      fileList.value.push(file);
      currentFile.value = file;
     
    };

    const removeFile = () => {
      if (currentFile.value) {
        console.log('Removing:', currentFile.value);
        fileList.value = fileList.value.filter((file) => file !== currentFile.value);
        currentFile.value = null;
      }
    };



        const isLoading = ref(false)
        const ticketForm = reactive({
            description:''
        });
        const rules = reactive({
            description: [
                {
                required: true,
                message: "Please input description",
                trigger: ["blur", "change"],
                },
            ],
           
        });
      
        const submitForm = async (formEl) => {
            if (!formEl) return;
            await formEl.validate((valid, fields) => {
                if (valid) {
                isLoading.value = true
                const formData = new FormData();
                formData.append("description", ticketForm.description);
                formData.append('file', currentFile.value?.raw);
               
                raiseTicket(formData)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                        ElNotification({
                            title: "Success",
                            message: response.data.message,
                            type: "success",
                        });
                        props.refresh()
                        emit('close-modal')
                        }else if (response.data.error) {
                        ElNotification({
                            title: 'Error',
                            message: response.data.error,
                            type: 'error',
                        });
                    }  else {
                        Object.values(response.data.errors).forEach((val) =>
                            ElNotification({
                                title: "Error",
                                message: val[0],
                                type: "error",
                            })
                        );
                        }
                    })
                    .catch((err) =>
                        console.log(err),
                    )
                    .finally(() => {
                        isLoading.value = false
                });
              } else {
                console.log("error submit!", fields);
              }
            });
        };
        
  
      return {
        ticketForm ,
        ticketFormRef,
        props,
        rules,
        submitForm,
        isLoading,
        handleBeforeUpload,
        addAttachment,
        removeFile
      };
    },

  };
  </script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 500px;
  min-width: 350px;
  height: 500px; 
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}
@media (min-width: 768px) {
    .card {
    margin-top: 5%;
    outline: 0;
    border-radius: 5px;
    max-width: 500px;
    min-width: 500px;
    height: 500px; 
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
}
}
.card-body {
  height: auto;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}


.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
.password-icon {
  cursor: pointer;
  margin-left: 5px;
}
</style>
  











  