<template>
    <failed-sms-table
      :sms="sms"
      :refresh="fetchData"
      :current_page="current_page"
      :total_pages="total_pages"
      :per_page="per_page"
     
    />
    </template>
    <script>
     import FailedSmsTable from '@/views/components/tables/admin/FailedSmsTable.vue';
     import {fetchFailedSms} from "@/api/admin/communication"
     import { ref, onMounted, reactive } from "vue";
     import {useStore} from 'vuex'
    
     const sms = ref([]);
     const per_page = ref(0);
     const current_page = ref(0);
     const total_pages = ref(0);
     
     
    export default {
      name: 'SentBox',
      components: {
        FailedSmsTable
      },
      setup(){
          const store = useStore()
          const fetchData = async (filter, page) => {
            // isLoading.value = true;
            let entity_params = {};
            try {
              if (filter == undefined) {
                entity_params = ref({});
              } else {
                entity_params = filter;
              }
              store.commit('isLoading', true)
              const response = await fetchFailedSms(entity_params, page);
              sms.value = response.data;
              console.log('response is',sms.value)
    
              const total_records = response.data.total || 0;
              per_page.value = response.data.per_page;
              current_page.value = response.data.current_page;
              
              if (per_page.value > 0 && total_records > 0) {
                  total_pages.value = Math.ceil(total_records / per_page.value);
              }
            } catch (error) {
              console.log(error);
            } finally {
              store.commit('isLoading', false)
            }
          };
          const paginate = reactive({
                current_page: current_page.value || 1,
                total_pages: total_pages.value,
                per_page: per_page.value,
            });
    
          onMounted(()=>{
            fetchData()
           
          })
          return {
            fetchData,
            sms,
            paginate,
            per_page,
            total_pages,
            current_page
          }
      },
    }
    </script>
    