<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-user-o text-primary me-2" aria-hidden="true"></i>Import Group Member</div>
      
        <el-form
            ref="groupMemberFormRef"
            :model="groupMemberForm"
            :rules="rules"
            label-width="150px"
            size=""
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div @click.prevent="downloadSample()" class="d-flex mb-4 align-items-center">
            <soft-button
                color="success"
                variant="outline"
                size="sm"
                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
            >
              <i class="fas fa-arrow-down" aria-hidden="true"></i>
            </soft-button>
            <div class="d-flex pointer flex-column">
              <h6 class="mb-1 text-dark text-sm">Download Sample</h6>
            </div>
          </div>

            <div class="row">
                <div class="col-md-12 mb-4">
                    <label class="el-form-item__label">Group</label>
                    <el-select v-model="groupMemberForm.group" label="" class="w-100" placeholder="" size=""  filterable>
                        <el-option
                        v-for="item in groups"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        />
                    </el-select>
                </div>

                <el-upload
                    class="upload-demo"
                    accept=".xls,.xlsx"
                    :before-upload="handleBeforeUpload"
                    :on-change="addAttachment"
                    :auto-upload="false"
                    :on-remove="removeFile"
                    action=""
                    :file-list="fileList"
                    >
                    <el-button type="primary">Click to upload</el-button>
                    <div class="el-upload__text ms-3">
                    upload an excel file
                    </div>
                    <template #tip>
                        <div class="el-upload__tip">
                        </div>
                    </template>
                 </el-upload>
               
               
            </div>
          </div>
          <div class="card--footer">
            <el-button
                type="danger"
                :loading="isLoading"
                @click="$emit('close-modal')"
            >Cancel</el-button
            >
            <el-button
                type="success"
                :loading="isLoading"
                @click="submitForm(groupMemberFormRef)"
            >Import</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
   import SoftButton from "@/components/SoftButton.vue";
  import { reactive, ref, onMounted } from "vue";
  import {fetchUnpagGroup} from "@/api/groups"
  import { useStore } from 'vuex';
  import { ElNotification } from "element-plus";
  import { sampleDownload, importGroupMember} from "@/api/groups"
  import { ElMessage } from "element-plus";
  const groupMemberFormRef = ref();


  export default {
    name: "email-form",
    components: {
        SoftButton,
    },
  
    props: {
      refresh: {
        type: Object,
        required: false,
      },
    },
  
    setup(props, {emit}) {
        const isLoading = ref(false)
        const store = useStore();
        const groups = ref([])
        const groupMemberForm = reactive({
            group:''
            
        });
        const rules = reactive({
            group: [
                {
                required: true,
                message: "Please input your password",
                trigger: ["blur", "change"],
                },
            ],
        });


        const currentFile = ref(null);
        const fileList = ref([]);

      const handleBeforeUpload = (file) => {
      const fileType = file.type;
      const validExcelTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];
      const isValidExcelType = validExcelTypes.includes(fileType);

      if (!isValidExcelType) {
        this.$message.error('Please upload a valid Excel file.');
        return false; 
      }

      return true; 
    }

    const addAttachment = (file) => {
      console.log(file);
      const newFormData = new FormData(); 
      newFormData.append('file', file.raw);
      fileList.value.push(file);
      currentFile.value = file;
     
    };


    const removeFile = () => {
      if (currentFile.value) {
        console.log('Removing:', currentFile.value);
        fileList.value = fileList.value.filter((file) => file !== currentFile.value);
        currentFile.value = null;
      }
    };

      async function downloadSample(){
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your download will start shortly. Please wait...",
        });

        await sampleDownload ()
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "sample-member-email.xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
           
            })
            .catch((err) => {
              console.log(err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      }
    }

       
        const fetchData = async()=>{
            store.commit('isLoading', true)
            try{
                await 
                fetchUnpagGroup()
                    .then((response) => {
                    groups.value = response.data;
                    console.log('groups', groups.value)
                });
            }catch{err => {console.log(err)}}
            finally{
                store.commit('isLoading', false)
            }
        } 
        onMounted(()=>{
            fetchData()
        })
      
        const submitForm = async (formEl) => {
          if (currentFile.value) {
            if (!formEl) return;
            await formEl.validate((valid, fields) => {
                if (valid) {
                isLoading.value = true
                const formData = new FormData();
                formData.append("group", groupMemberForm.group);
                formData.append('file', currentFile.value.raw);

                importGroupMember(formData)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                        ElNotification({
                            title: "Success",
                            message: response.data.message,
                            type: "success",
                        });
                        props.refresh()
                        emit('close-modal')
                        }else if (response.data.error) {
                        ElNotification({
                            title: 'Error',
                            message: response.data.error,
                            type: 'error',
                        });
                    }  else {
                        Object.values(response.data.errors).forEach((val) =>
                            ElNotification({
                                title: "Error",
                                message: val[0],
                                type: "error",
                            })
                        );
                        }
                    })
                    .catch((err) =>
                        console.log(err),
                    )
                    .finally(() => {
                        removeFile();
                        fileList.value = [];
                        isLoading.value = false
                });
              } else {
                console.log("error submit!", fields);
              }
            });
            }else{
              ElNotification({
                      title: 'Error',
                      message: 'Please upload an excel file',
                      type: 'error',
                    });
            }
        };
        
  
      return {
        groupMemberForm ,
        groupMemberFormRef,
        props,
        rules,
        groups,
        submitForm,
        isLoading,
        downloadSample,
        handleBeforeUpload,
        addAttachment
        
      };
    },

  };
  </script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 500px;
  height: 380px; 
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}
.card-body {
  height: auto;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}


.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
.password-icon {
  cursor: pointer;
  margin-left: 5px;
}
.pointer {
  cursor: pointer;
}
</style>
  











  