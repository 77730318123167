<template>
  <soft-pagination class="">
    <soft-pagination-item @click="onClickFirstPage" first  :label="DoubleLeftIcon" />
    <soft-pagination-item @click="onClickPreviousPage" prev :disabled="isDisabled" />
    <div v-for="page in pages" :key="page">
      <soft-pagination-item
          @click="onClickPage(page)"
          :class="{ active: isPageActive(page) }"
          :label="page"
      />
    </div>
    <soft-pagination-item @click="onClickNextPage" next />
    <soft-pagination-item @click="onClickLastPage" last :label="DoubleRightIcon"  />
  </soft-pagination>
</template>

<script>
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";

export default {
  components: { SoftPagination, SoftPaginationItem },
  props: {
    DoubleLeftIcon: {
      type: [String, Object],
      required: false,
      default: "<<",
    },
    DoubleRightIcon: {
      type: [String, Object],
      required: false,
      default: ">>",
    },
      maxVisibleButtons: {
        type: Number,
        required: false,
        default: 3
      },    
      totalPages: {
        type: Number,
        required: true
      },
      perPage: {
        type: Number,
        required: true
      },
      currentPage: {
        type: Number,
        required: true
      }
    },
    computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages-1);
        i++
      ) {
        if(i>0){
          range.push(i);
      }
        }
      if(this.totalPages > 1){
        range.push("...", this.totalPages);
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      if(this.currentPage <= 1){
       return this.currentPage === 1;
      }
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
  
    onClickNextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('pagechanged', this.currentPage + 1);
      }
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
    
  }
  };
  </script>
