<template>
  <div class="py-4 container-fluid">
    <div  v-if="showTable == false">
    <div>Make your own report, by default all columns are switched on, you can choose to switch off the columns you don't need in your report, proceed to select the report duration and choose to view or download excel or pdf</div>
    <div class="row align-items-center">
      <div class="col-md-4 bg-transparent text-start">
        <div class=" bg-transparent mt-2">
            <el-form
            ref="smsFormRef"
            :model="smsForm"
            :rules="rules"
            label-width="auto"
            class="demo-ruleForm "
            size="large"
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
            >
            <el-form-item class="d-flex align-items-center gap-6">
              <div style="width: 160px;">Customer Name</div>
              <el-switch v-model="smsForm.name" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item>

            <el-form-item  class="d-flex align-items-center gap-6">
              <div style="width: 160px;">Phone Number</div>
              <el-switch v-model="smsForm.phone" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item>

            <el-form-item  class="d-flex align-items-center gap-6">
              <div style="width: 160px;">Sent By</div>
              <el-switch v-model="smsForm.sent_by" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item>

            <el-form-item  class="d-flex align-items-center gap-6">
              <div style="width: 160px;">Type (Single)</div>
              <el-switch v-model="smsForm.single" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item>

            <el-form-item  class="d-flex align-items-center gap-6">
              <div style="width: 160px;">Type (Bulk)</div>
              <el-switch v-model="smsForm.bulk" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item>

            <el-form-item  class="d-flex align-items-center gap-6">
              <div style="width: 160px;">Type (Custom)</div>
              <el-switch v-model="smsForm.custom" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item>

            <el-form-item  class="d-flex align-items-center gap-6">
              <div style="width: 160px;">Status(Failed Sms)</div>
              <el-switch v-model="smsForm.failed" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item>

            <el-form-item  class="d-flex align-items-center gap-6">
              <div style="width: 160px;">Status(Successful Sms)</div>
              <el-switch v-model="smsForm.success" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item>

            <el-form-item  class="d-flex align-items-center gap-6">
              <div style="width: 160px;">Message</div>
              <el-switch v-model="smsForm.message" class="ml-2 me-1" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item>
            </el-form>
        </div>
    </div>
      <div class="col-md-4 text-center">
        <el-date-picker
          v-model="smsForm.date_range"
          type="daterange"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :shortcuts="shortcuts"
          class="w-100"
        />
      </div>

      <div class="col-md-4 text-center">
        <div class="mt-2">
          <el-button
            type="primary"
            @click="submitForm(smsFormRef)"
          >
          <i class="fa fa-eye" aria-hidden="true"></i>&nbsp; View On-screen
          </el-button>
        </div>
        <div class="mt-5">
          <el-button
            type="success"
            @click="submitExcelForm(smsFormRef)"
          >
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>&nbsp; Download Excel
          </el-button>
        </div>
           
        <div class="mt-5">
          <el-button
            type="warning"
            @click="submitPdfForm(smsFormRef)"
          >
          <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp; Download PDF
        </el-button>
        </div>
      </div>
    </div>
  </div>
    <sms-report-table
      v-else
      :sms="sms"
      :current_page="current_page"
      :total_pages="total_pages"
      :per_page="per_page"
      @back = "showTable=false"
     
    />
  </div>
</template>
<script>
import SmsReportTable from '@/views/components/tables/SmsReportTable.vue';
import { ref, reactive } from 'vue';
import {fetchSmsReport, smsReportExcel,  smsReportPdf} from "@/api/reports"
import {useStore} from 'vuex'
import { ElNotification, ElMessage } from "element-plus";


export default {
  components:{
    SmsReportTable
  },

  setup(){
    const showTable = ref(false)
     const store = useStore();
     const sms = ref([]);
     const per_page = ref(0);
     const current_page = ref(0);
     const total_pages = ref(0);


    const smsFormRef = ref()
    const smsForm = reactive({
      name:true,
      phone:true,
      sent_by:true,
      failed:true,
      single:true,
      bulk:true,
      custom:true,
      success:true,
      message:true,
      date_range:''

    });

    
    const submitForm = async()=>{
      
      const date = smsForm.date_range;
      if (date === '') {
        ElNotification({
          title: "Error",
          message: "Please Input date range",
          type: "error",
        })
        return;
      } 

       const trueItems = Object.entries(smsForm).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      let entity_params = {
        ...trueItems
      };
      let url = "fetch-sms-report?"
      url = url + 'page=1'
      if(entity_params.name != undefined){
        url = url + '&name=name'
      }
      if(entity_params.phone != undefined){
        url = url + '&phone=phone'
      }
      if(entity_params.sent_by != undefined){
        url = url + '&sent_by=sent_by'
      }
      if(entity_params.failed != undefined){
        url = url + '&failed=failed'
      }
      if(entity_params.single != undefined){
        url = url + '&single=single'
      }
      if(entity_params.bulk != undefined){
        url = url + '&bulk=bulk'
      }
      if(entity_params.custom != undefined){
        url = url + '&custom=custom'
      }
      if(entity_params.success != undefined){
        url = url + '&success=success'
      }
      if(entity_params.message != undefined){
        url = url + '&message=message'
      }
      url = url + '&date_range='+smsForm.date_range
      console.log('url is', url)
        try {
          store.commit('isLoading', true)
          await fetchSmsReport(url)
          .then((response)=>{
            console.log('response is',response)
            if (response.success){
              sms.value = response;
              console.log('response is',sms.value)

              const total_records = response.data.total || 0;
              per_page.value = response.data.per_page;
              current_page.value = response.data.current_page;
              
              if (per_page.value > 0 && total_records > 0) {
                  total_pages.value = Math.ceil(total_records / per_page.value);
              }
              showTable.value = true
            }else if (response.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.error,
                    type: 'error',
                  });
              }
          },
           )
          
        } catch (error) {
          console.log(error);
        } finally {
         
          store.commit('isLoading', false)
        }
    }

    const submitExcelForm = async () => {
      const date = smsForm.date_range;
      if (date === '') {
        ElNotification({
          title: "Error",
          message: "Please Input date range",
          type: "error",
        })
        return;
      } 

       const trueItems = Object.entries(smsForm).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      let entity_params = {
        ...trueItems
      };
      let url = "sms-report-excel?"
      url = url + 'page=1'
      if(entity_params.name != undefined){
        url = url + '&name=name'
      }
      if(entity_params.phone != undefined){
        url = url + '&phone=phone'
      }
      if(entity_params.sent_by != undefined){
        url = url + '&sent_by=sent_by'
      }
      if(entity_params.failed != undefined){
        url = url + '&failed=failed'
      }
      if(entity_params.single != undefined){
        url = url + '&single=single'
      }
      if(entity_params.bulk != undefined){
        url = url + '&bulk=bulk'
      }
      if(entity_params.custom != undefined){
        url = url + '&custom=custom'
      }
      if(entity_params.success != undefined){
        url = url + '&success=success'
      }
      if(entity_params.message != undefined){
        url = url + '&message=message'
      }
      url = url + '&date_range='+smsForm.date_range
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });
        
        await smsReportExcel(url)
            .then((Response) => {
              console.log('response is this', Response)
              
                let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "sms_" + Math.round(+new Date() / 1000) + ".xlsx"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
              
             
            })
            .catch((err) => {
              console.log('error',err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        //
      }
    };

    const submitPdfForm = async () => {
      const date = smsForm.date_range;
      if (date === '') {
        ElNotification({
          title: "Error",
          message: "Please Input date range",
          type: "error",
        })
        return;
      } 

       const trueItems = Object.entries(smsForm).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      let entity_params = {
        ...trueItems
      };
      let url = "sms-report-pdf?"
      url = url + 'page=1'
      if(entity_params.name != undefined){
        url = url + '&name=name'
      }
      if(entity_params.phone != undefined){
        url = url + '&phone=phone'
      }
      if(entity_params.sent_by != undefined){
        url = url + '&sent_by=sent_by'
      }
      if(entity_params.failed != undefined){
        url = url + '&failed=failed'
      }
      if(entity_params.single != undefined){
        url = url + '&single=single'
      }
      if(entity_params.bulk != undefined){
        url = url + '&bulk=bulk'
      }
      if(entity_params.custom != undefined){
        url = url + '&custom=custom'
      }
      if(entity_params.success != undefined){
        url = url + '&success=success'
      }
      if(entity_params.message != undefined){
        url = url + '&message=message'
      }
      url = url + '&date_range='+smsForm.date_range
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });
        
        await smsReportPdf(url)
            .then((Response) => {
              console.log('response is this', Response)
              
                let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "sms_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
              
             
            })
            .catch((err) => {
              console.log('error',err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        //
      }
    };

  return {
    smsForm,
    smsFormRef,
    submitForm,
    per_page,
    total_pages,
    current_page,
    showTable,
    sms,
    submitExcelForm,
    submitPdfForm

  }
}
}
</script>



